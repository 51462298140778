import React, { useEffect, useRef } from "react";
import PureLink from "../component/PureLink";
import { LoadingFeed } from "../Feed";
import { Card } from "../card/Card";
import KalakaAPI, { messagePopup } from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import CheckMark from "../component/CheckMark";
import { Box, Button, Flex, Icon, Input, Spacer, Stack, useToast } from "@chakra-ui/react";
import { Separator } from "../card/Separator";
import { IconParameter } from "../component/IconParameter";
import { MdOutlineDeleteForever, MdOutlinePassword } from "react-icons/md";


export default function Settings(props)
{
    const toast = useToast();

    const [loading, setLoading] = React.useState(true);
    const [values, setValues] = React.useState({});
    const refs = useRef([]);
    const [changed, setChanged] = React.useState(false);

    const [demandReload, setDemandReload] = React.useState(false);
    
    useEffect(() => {
        refs.current = refs.current.slice(0, props.children.filter(child => child.type === Setting).length);
    }, [props.items]);
    
    const save = (back = true) =>
    {
        let result = {back: back ? "1":"0"};
        
        refs.current.forEach((current, i) => 
        {
            result[current.name] = current.value || current.checked || current.selected;
        });
        
        console.log(result);
        
        KalakaAPI.postSettings(result)
        .then(res => { 
            if(res.ok)
            {
                toast({
                    title: LanguageHelper.get("Settings_saved"),
                    status: "success"
                })
                setValues(res.data);
                setChanged(false);
                if(demandReload)
                    window.location.reload();
            } else
            {
                messagePopup(res);
            }
        })
    }
    const getData = () =>
    {
        KalakaAPI.postSettings({back: "0"})
        .then(res => {
            if(res.ok)
            {
                setValues(res.data);
            } else
            {
                messagePopup(res);
            }
        })
        .finally(() => {
            setLoading(false);
        });
    }
    useEffect(getData, []);
    
    const onChange = () =>
    {
        setChanged(true)
    }
    
    if(loading)
    {
        return <LoadingFeed/>
    }
    let children = props.children.map((element, i) => {
        return <element.type
            {...element.props}
            onChange={(event) => {
                if(element.props.reload)
                {
                    setDemandReload(true)
                }
                onChange(event)
            }}
            reference={el => refs.current[i] = el} 
            value={values[element.props.name] || null}
            key={i}
            >
            </element.type>
    });
    
    let saveButton = <Button ml="1rem" mt="1rem" onClick={save} colorScheme="blue" disabled={!changed}>{LanguageHelper.get("Save")}</Button>;
    return (<>
        {saveButton}
        <Card>
            {children}
            <Box p={"1rem"}>
                <PureLink to="/changepassword">
                    <Button leftIcon={<Icon as={MdOutlinePassword} />} variant={"outline"} colorScheme="blue">{LanguageHelper.get("Change_password")}</Button>
                </PureLink>
            </Box>
            <Separator/>
            <Box p={"1rem"}>
                <PureLink to="/deleteaccount">
                    <Button leftIcon={<Icon as={MdOutlineDeleteForever} />} variant={"outline"} colorScheme="red" >{LanguageHelper.get("Delete_account")}</Button>
                </PureLink>
            </Box>
        </Card>
        {saveButton}
    </>
    );
}
export function Setting(props)
{
    let input; 
    switch (props.type)
    {
    case "boolean":
        input = (<CheckMark reference={props.reference} name={props.name} onChange={props.onChange} value={props.value}></CheckMark>);
        break;
    case "text":
        input = (<input ref={props.reference} name={props.name} onChange={props.onChange} defaultValue={props.value} className="textinput short rightfloat" type="text"></input>);
        break;
    case "select":
        input = 
        <select ref={props.reference} name={props.name} onChange={props.onChange} defaultValue={props.value} className="custom-select rightfloat">
            {props.list.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
        </select>;
        break;
    case "const":
        input = props.value;
        break;
    default:
        break;
    }
    
    return(<>
        <Flex
            p=".5rem 1rem .5rem .4rem"
            alignItems={"center"}
        >
            <Box display={"inline-block"}>
                <IconParameter icon={props.icon} >
                    {props.children}
                </IconParameter>
            </Box>
            <Spacer />
            <Box display={"inline-block"} >
                {input}
            </Box>
        </Flex>
        <Separator />
    </>);
}