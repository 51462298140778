import PureLink from "../component/PureLink";
import KalakaAPI, { messagePopup, PREFIX, useAPI } from "./KalakaAPI";

import React, { useRef, useState } from 'react';
import { FeedTitle } from "../card/CardComponents";
import ErrorPanel from "../component/ErrorPanel";
import { LoadingFeed } from "../Feed";

import "../../css/LanguageConfig.css";

import { Button } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import reactStringReplace from 'react-string-replace';
import { useSearchBar } from "../component/FilterBar";
import { ToolButton, ToolGroup } from "../component/Tool";

let LanguageHelper = {  

    data: {},
    loaded: false,
    lang: null,
    
    get: (item, inserts) => 
    {
        let res = LanguageHelper.data[item];
        if(!res)
        {
            console.log("[LanguageHelper]", item, "not found, upload here:", process.env.REACT_APP_DOMAIN + "/addtext?key="+item);
            return <PureLink to={{pathname: "/addtext", search: "?key="+item}}>{"["+item+"]"}</PureLink>;    
        }
        
        if(inserts)
        {   
            res = LanguageHelper.insertVariables(res, inserts);
        }
        res = LanguageHelper.clearVariables(res);
        
        //console.log("LanguageHelper", item, res);
        return res;
            
    },
    has: (item) =>
    {
        return LanguageHelper.data[item] ? true : false
    },
    
    insertVariables: (text, inserts) =>
    {
        let res = [text];
        
        Object.entries(inserts).forEach(([key, value]) => 
        {   
            res = reactStringReplace(res, "{" + key + "}", (match, i) => <b>{value}</b>)
        })
            
        return res;
    },
    clearVariables: (elem) =>
    {
        return reactStringReplace(elem, "{*}", () => "");
    },
    
    initLanguage : () =>
    {
        let browser_language = localStorage.getItem(PREFIX + "language") || navigator.language.slice(0, 2);

        return KalakaAPI.getLanguage({ browser_language }).then(
            result =>
            {
                if (result.ok === "1" || result.ok === 1) {
                    Object.assign(LanguageHelper.data, result.data);
                    console.warn("[Kalaka] Language initialized", LanguageHelper.data);
                    LanguageHelper.loaded = true;
                    return Promise.resolve();
                }
                else {
                    console.error("Could not load language", "browser", browser_language, "result", result);
                }
            }
        )
    }
};

export default LanguageHelper;  

export function LanguageConfig(props)
{
    const [error, loading, data, update] = useAPI("/api/LanguageConfig.php", KalakaAPI.post)
    const [searchBar, search] = useSearchBar("languageconfig")
    
    console.log(data)
    
    if(error)
        return <ErrorPanel>{error}</ErrorPanel>
    if(loading)
        return <LoadingFeed></LoadingFeed>
    return <>
        <FeedTitle>{LanguageHelper.get("Language_config")}</FeedTitle>
        
        <div>
            <ToolGroup>
                <PureLink to="/addtext">
                    <ToolButton icon={MdAdd}></ToolButton>
                </PureLink>
            </ToolGroup>
            {searchBar}
        </div>
        
        <div className="LanguageConfig">
            <table >
                <thead>
                    <tr>
                        <td>id</td><td>hu</td><td>en</td><td></td>
                    </tr>
                </thead>
                <tbody>
                    {data.filter(text => {
                        return text.id.toUpperCase().includes(search.toUpperCase()) 
                            || text.en.toUpperCase().includes(search.toUpperCase()) 
                            || text.hu.toUpperCase().includes(search.toUpperCase())
                    })
                        .map(text => <LanguageLine key={text.id} text={text}></LanguageLine>)}
                </tbody>
            </table>
        </div>
    </>
}

const languages = ["hu", "en"]

function LanguageLine(props)
{
    const [saved, setSaved] = useState(true)
    const refs = {
        en: useRef(),
        hu: useRef()
    }
    
    let text = props.text
    return <tr>
        <td>{text.id}</td>
        {languages.map(lang => <td key={lang}>
            <textarea 
                ref={refs[lang]}
                style={{height: refs[lang].scrollHeight + "px"}}
                defaultValue={text[lang]} 
                onChange={() => setSaved(false)}
            ></textarea>
        </td>)}
        <td>
            {!saved &&
                <Button 
                    colorScheme="blue" 
                    onClick={() =>
                    {
                        let res = {key: text.id}
                        Object.entries(refs).forEach(([lang, ref]) => res[lang] = ref.current.value)
                        KalakaAPI.post("/api/new_text.php", res)
                        .then(messagePopup)
                        .then(() => setSaved(true))
                    }}
                >
                    {LanguageHelper.get("Save")}
                </Button>
            }
        </td>
    </tr>
}
