import { useEffect, useState } from "react"
import { PREFIX } from "./KalakaAPI"

const createKey = (type, id, key) => {
    return PREFIX + type + "_" + id + "_" + key
}

export const usePersistentState = (type, key, param, defaultValue) =>
{
    const storageKey = createKey(type, key, param)
    let [value, setValue] = useState(defaultValue)
    const [loading, setLoading] = useState(true)

    const getItem = () =>
    {
        let local = localStorage.getItem(storageKey)
        if(local !== null)
        {
            return JSON.parse(local)
        }
        return local
    }

    useEffect(() => {
        let local = getItem()
        if(local !== null)
        {
            setValue(local)
        }
        setLoading(false)
    },[])

    // before loading, try to get from localstorage, after loading we should not use localstorage
    value = loading ? (getItem() || value) : value;

    return [
        value,
        (value) => 
        {
            setValue(value)
            value = JSON.stringify(value)
            localStorage.setItem(storageKey, value)
        },
    ]
}

export const deletePersistentState = (type, key, param) =>
{
    const storageKey = createKey(type, key, param)
    localStorage.removeItem(storageKey)
}
export const deleteAllCache = () => 
{
    Object.keys(localStorage).forEach(key => {
        if(key.startsWith(PREFIX))
        {
            localStorage.removeItem(key)
        }
    })
}