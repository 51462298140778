import { Box } from "@chakra-ui/react";
import { stringToDate } from "../util/DateHelper";
import LanguageHelper from "../util/LanguageHelper";

export const ContextBar = (props) => <div className="ContextBar">{props.children}</div>;
export const PostBar = (props) => <div className="PostBar">{props.children}</div>;
export const PostTitle = (props) => <div className="PostTitle">{props.children}</div>;
export const PriceTag = (props) => <div className="PriceTag">{props.price} ft {props.unit ? "/ " + (props.unitnumber || "") + " " + LanguageHelper.get(props.unit) : ""}</div>;
export const PostDescription = (props) => <div className="PostDescription">{props.children}</div>;
export const PostContent = (props) => <div className="PostContent">{props.children}</div>;

export const SmallTitle = (props) => <div className="SmallTitle">{props.children}</div>;
export const BoldTitle = (props) => <div className="PostTitle BoldTitle">{props.children}</div>;

export const FeedText = (props) => <div className="FeedText">{props.children}</div>;
export const FeedTitle = (props) => <div className="FeedTitle">{props.children}</div>;

// YYYY-MM-DD HH:MM:SS
export const TimeBar = (props) => {
    if(props.time == null)
        return null;

    const calc = (time) => {
        let date = stringToDate(time);
        let now = new Date();
        let diff = now - date;
        let years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
        let months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
        let days = Math.floor((diff + ((24 - now.getHours()) * 1000 * 60 * 60)) / (1000 * 60 * 60 * 24));
        let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        // let seconds = Math.floor((diff % (1000 * 60)) / 1000);
        if (years > 1) {
            return years + " " + LanguageHelper.get("years_ago");
        }
        if (years === 1) {
            return years + " " + LanguageHelper.get("year_ago");
        }
        if (months > 1) {
            return months + " " + LanguageHelper.get("months_ago");
        }
        if (months === 1) {
            return months + " " + LanguageHelper.get("month_ago");
        }
        if (days > 1) {
            return days + " " + LanguageHelper.get("days_ago");
        }
        if (days === 1) {
            return LanguageHelper.get("yesterday");
        }
        if (hours > 1) {
            return hours + " " + LanguageHelper.get("hours_ago");
        }
        if (hours === 1) {
            return hours + " " + LanguageHelper.get("hour_ago");
        }
        if (minutes > 1) {
            return minutes + " " + LanguageHelper.get("minutes_ago");
        }
        return LanguageHelper.get("just_now");
    };
    return <Box mb={".5rem"} m={props.m || props.margin}>
        {calc(props.time)}
    </Box>;
};

