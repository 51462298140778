import { React, Component } from "react";
import Feed, { LoadingFeed } from "../Feed";
import { Card } from "../card/Card";
import Form, {getOfferInputs, InputFlag, Line } from "../component/Form";
import KalakaAPI from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";

export default class NewOffer extends Component 
{
    state = {loading: true, products: {}, selectedProducts: []}

    componentDidMount = () =>
    {
        KalakaAPI.getFeed({type: "myproducts", offset: -1})
        .then(res =>
            {
                let obj = {}
                res.data.forEach(product => obj[product.id] = product)
                this.setState({products: obj, loading: false})
            })
    }
    addProduct = (e) =>
    {
        if(e.target.value !== 0 && !this.contains(this.state.selectedProducts, id => id === e.target.value))
        {
            this.setState({selectedProducts: [...this.state.selectedProducts, e.target.value]})
        }
        e.target.value = 0
    }
    removeProduct = (id) =>
    {
        let res = this.state.selectedProducts.filter(productId => productId !== id)
        this.setState({selectedProducts: res})
    }
    contains = (arr, f) =>
    {
        return arr.filter(f).length > 0
    }    
   
    render = () =>
    {
        if(this.state.loading)
            return <LoadingFeed />

        let children = [
            <InputFlag label={LanguageHelper.get("Products")} />,
            this.state.selectedProducts.map(id => <Line key={id} className="opening" onClose={() => this.removeProduct(id)}>
                <input type={"text"} className={"textinput short "} disabled={true} value={this.state.products[id].name}></input>
            </Line>),
            <select className="custom-select" onChange={this.addProduct}>
                <option value={0}>{LanguageHelper.get("Add_product")}</option>
                {
                    Object.values(this.state.products)
                    .filter(product => !this.contains(this.state.selectedProducts, id => id === product.id))
                    .map(product => <option key={product.id} value={product.id}>{product.name}</option>)}
            </select>,
            ...getOfferInputs()
        ]
        return <Card>
            <Form onSubmit={data => KalakaAPI.offer({...data, product_id: this.state.selectedProducts})} text={LanguageHelper.get("Offer_publish")}>
                {children}
            </Form>
        </Card>
    }
}