import React, { Component, useEffect, useRef, useState } from "react";

import { GetURLParam } from "../util/URLParams";
import "../../css/Form.css";

import KalakaAPI from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";

import { Button, createStandaloneToast, Flex, IconButton, useToast } from "@chakra-ui/react";
import { MdCancel, MdOutlineDoNotDisturb } from "react-icons/md";

export default function Form(props)
{
    const [loading, setLoading] = useState(false);
    
    const toast = useToast()
    const references = useRef([])
    
    const [inputs, setInputs] = useState(null);
    const [message, setMessage] = useState(null);
    const [wronginput, setWrongInput] = useState(null);
    
    useEffect(() => {
        if(props.inputs)
        {
            props.inputs.forEach(input =>
            {
                let value;
                if(value === GetURLParam(input.name))
                {
                    input.defaultValue = value;
                }
                return input.ref = React.createRef()
            });
            
            
            for(let i = 0; i < props.inputs.length; i++)
            {
                props.inputs[i].id = i++;
            }
            
            setInputs(props.inputs);
        } else {
            references.current = references.current.slice(0, props.children?.length);
        }
    }, [])
    // collect and pass pure data
    const submit = (e) =>
    {
        e.preventDefault();
        
        if(loading) return;
        
        setLoading(true);
        
        let result = props.constantInputs || {};
        
        if(inputs)
        {
            inputs.map((input) => {
                result[input.name] = input.ref.current.value;
                return null;
            });
        } else if(props.children)
        {
            references.current.forEach((current, i) => 
            {
                if(current.name)
                    result[current.name] = current.value || current.checked || current.selected;
            });
        }
        
        props.onSubmit(result)
            .then(res => 
            {
                console.log(res);
                setLoading(false);
                if(res.ok)
                {
                    finish(res)
                } else {
                    setMessage(res.data?.message || res.message)
                    setWrongInput(res.data?.wronginput || res.wronginput);
                }
                
            })
            .catch(err =>
            {
                setLoading(false);
                setMessage("0")
            });
            
    }
    
    const finish = (res) =>
    {
        setMessage("")
        
        if(typeof props.onSuccess === "function")
            props.onSuccess(res)
        if(props.messageOnSuccess)
            toast({
                title: props.messageOnSuccess,
                status: "success",
            })
        if(props.backOnSuccess)
            window.history.back();
        else    
            references.current.forEach(current => {
                if(current)
                {
                    current.value = null
                    current.checked = false
                    current.selected = null
                }
            });
    }
    
    
    const render = () =>
    {
        let lines;
        if(inputs)
        {
            lines = inputs.map((input, i) => (
                <FormLine key={input.id} 
                    reference={el => references.current[i] = el} 
                    name={input.name} 
                    flag={input.flag} 
                    type={input.type} 
                    list={input.list} 
                    placeholder={input.placeholder} 
                    value={GetURLParam(input.name) || input.value}
                    classnames={input.classnames}
                    wronginput={wronginput}
                />
            ));
        } else if(props.children)
        {
            let children = props.children;

            if(!Array.isArray(children))
                children = [children];
                
            lines = children.map((child, i) => 
                {
                    if(!child.props?.name)
                        return child
                    return <child.type 
                        key={child.props.name}
                        {...child.props} 
                        reference={el => references.current[i] = el} 
                        wrong={wronginput === child.props.name}
                        value={GetURLParam(child.props.name) || child.props.defaultValue || child.props.value}
                    >   
                        {child.props.children}
                    </child.type>
                });
        }
        
        return (
            <div id="form" className="form" style={{scrollMarginTop: "2rem"}}>
                <Line className="formtitle">
                    <Flex alignItems={"center"} gap="1rem" fontWeight={700}>
                        {props.icon}
                        {props.text}
                    </Flex>
                </Line>
                
                <form onSubmit={submit}>
                    {lines}
                    <Line className="messageLine">
                        <p className="message flag">
                            {message ? LanguageHelper.get("message_" + message) || message : ""}    
                        </p>
                    </Line>
                    <Line>
                        <SubmitButton disabled={loading} text={props.submitText || "Tovább"}/>
                    </Line>
                </form>
            </div>
        )
    }
    return render()
}

export function InputFlag(props) { return (<><label className="flag">{props.label}</label><br/></>)}

class FormLine extends React.Component
{
    // ref name flag type
    
    getInput = () =>
    {
        let classnames = this.props.classnames;
        if(this.props.wronginput === this.props.name)
        {
            classnames += " wrong";
        }
        switch (this.props.type)
        {
            case "text":
            case "password":
                return (
                    <input 
                        ref={this.props.reference} 
                        name={this.props.name} 
                        type={this.props.type}
                        className={"textinput " + classnames}
                        autoComplete="off"
                        defaultValue={this.props.value}
                        placeholder={this.props.placeholder}
                        ></input>
                );
            case "number":
                return (
                    <input 
                        ref={this.props.reference} 
                        name={this.props.name} 
                        type={this.props.type}
                        className={"textinput numberinput " + classnames}
                        autoComplete="off"
                        defaultValue={this.props.value}
                        placeholder={this.props.placeholder}
                        ></input>
                );
            
            case "textarea":
                return (
                    <textarea 
                        ref={this.props.reference} 
                        name={this.props.name} 
                        type={this.props.type}
                        className="textinput" 
                        autoComplete="off"
                        placeholder={this.props.placeholder}
                        ></textarea>
                );
            case "date":
                return (
                    <input 
                        ref={this.props.reference} 
                        name={this.props.name} 
                        type="date"
                        className={"textinput date " + classnames}
                        autoComplete="off"
                        defaultValue={this.props.value || new Date().toJSON().slice(0,10)}
                        placeholder={this.props.placeholder}
                        ></input>
                );
            case "select":
                if(!this.props.list) return (
                    <div>
                        <select className="custom-select">
                            
                        </select>
                    </div>
                )
                return (
                    <div >
                        <select className={"custom-select " + classnames}  ref={this.props.reference} >
                            {this.props.list.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                );
            default:
                console.warn("Invalid FormLine type");
        }
    }
    
    render = () =>
    {
        return (
            <div>
                <div className="line">
                    {this.props.flag ? <span><label className="flag" htmlFor={this.props.name}>{this.props.flag}</label> <br></br></span> : ""}
                    {this.getInput()}
                </div>
            </div>
        )
    }
}

export class TextInput extends Component
{
    render = () => <Line>
        <InputFlag label={this.props.label} />
        <input 
            type="text" 
            className={"textinput " + (this.props.wrong ? "wronginput" : "")}
            name={this.props.name} 
            label={this.props.label}
            defaultValue={this.props.value}
            readOnly={false}
            ref={this.props.reference}
        ></input>
    </Line>
}
export class TextAreaInput extends Component
{
    render = () => <Line className="tall">
        <InputFlag label={this.props.label} />
        <textarea 
            className={"textinput " + (this.props.wrong ? "wronginput" : "")}
            name={this.props.name} 
            label={this.props.label}
            defaultValue={this.props.value}
            readOnly={false}
            ref={this.props.reference}
        ></textarea>
    </Line>
}
export class PasswordInput extends Component
{
    render = () => <Line>
        <InputFlag label={this.props.label} />
        <input 
            type="password" 
            className={"textinput " + (this.props.wrong ? "wronginput" : "")}
            name={this.props.name} 
            label={this.props.label}
            defaultValue={this.props.value}
            readOnly={false}
            ref={this.props.reference}
        ></input>
    </Line>
}
export class NumberInput extends Component
{
    render = () => <Line>
        <InputFlag label={this.props.label} />
        <input 
            type="number" 
            className={"textinput " + (this.props.wrong ? "wronginput" : "")}
            name={this.props.name} 
            label={this.props.label}
            defaultValue={this.props.value}
            readOnly={false}
            ref={this.props.reference}
        ></input>
    </Line>
}
  
export class DateTimeInput extends Component
{
    render = () => <Line>
        <InputFlag label={this.props.label} />
        <input 
            type="datetime-local" 
            className={"textinput date " + (this.props.wrong ? "wronginput" : "")}
            name={this.props.name} 
            label={this.props.label}
            defaultValue={this.props.value || new Date().toLocaleDateString()}
            readOnly={false}
            ref={this.props.reference}
        ></input>
    </Line>
}
export class DateInput extends Component
{
    render = () => <Line>
        <InputFlag label={this.props.label} />
        <input 
            type="date" 
            className={"textinput date " + (this.props.wrong ? "wronginput" : "")}
            name={this.props.name} 
            label={this.props.label}
            defaultValue={this.props.value || new Date().toLocaleDateString()}
            readOnly={false}
            ref={this.props.reference}
        ></input>
    </Line>
}
export class Select extends Component
{
    render = () => 
    {
        let list = this.props.list?.map((item) => <option key={item.id} value={item.id} selected={item.id == this.props.value}>{item.name}</option>);
        
        return <Line>
            {this.props.label ? <InputFlag label={this.props.label} /> : null}
            <select 
                className={"custom-select " + (this.props.wrong ? "wronginput" : "")}  
                ref={this.props.reference} 
                name={this.props.name}
                onChange={this.props.onChange}
            >
                {list || "..."}
            </select>
        </Line>
    }
}
export class GroupSelect extends Component
{
    state = {groups: []}

    componentDidMount = () =>
    {
        KalakaAPI.getGroups()
        .then(groups => this.setState({groups: groups}));
    }

    render = () => 
    {
        return <Line>
            <InputFlag label={this.props.label} />
            <select 
                className={"custom-select " + (this.props.wrong ? "wronginput" : "")}  
                ref={this.props.reference} 
                name={this.props.name}
            >
                <option value={""}></option>
                {this.state.groups.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
        </Line>
    }
}
export class ProducerSelect extends Component
{
    state = {producers: null}
    render = () => 
    {
        if(!this.state.producers)
        {
            KalakaAPI.getProducers()
            .then(producers => 
                {
                    this.setState({producers: producers.map((item) => 
                        <option key={item.id} value={item.id} selected={item.id === this.props.value} >{item.name}</option>)});
                });
        }
        return <Line>
            <InputFlag label={this.props.label} />
            <select 
                className={"custom-select " + (this.props.wrong ? "wronginput" : "")}  
                ref={this.props.reference}
                name={this.props.name}
            >
                <option value={""}></option>
                {this.state.producers || "..."}
            </select>
        </Line>
    }
}

export class ScoreInput extends Component
{
    state={value: 5}
    constructor(props)
    {
        super(props);
        this.state.value = parseInt(props.value) || 5; 
    }
    setValue = (value) =>
    {
        this.setState({value: value});
    }
    render = () =>
    {
        let content = [];
        let defaultValue = this.state.value || 5;
        
        for(let i = 1; i <= 5; i++)
        {
            let stars = [];
            for(let j = 1; j <= i; j++)
                stars.push(<span key={j} className="staricon">★</span>);
            
            content.push(<label key={i}>
                <input type="radio" name={this.props.name} value={i} defaultChecked={this.state.value === i} onClick={() => this.setValue(i)}/>
                {stars}
            </label>)
        }
        return <div className="rating">
            <input name="score" value={this.state.value} readOnly style={{display: "none"}} type="number" ref={this.props.reference}></input>
            {content}
        </div>
    }
}
export class SubmitButton extends React.Component
{
    render = () =>
    {
        return (
            <Button
                type="submit" 
                colorScheme="blue"
                borderRadius={".2rem"}
                disabled={this.props.disabled}
            >
                {this.props.text}
            </Button>
        );
    }
}
export const Line = (props) => 
{
    let [closing, setClosing] = useState(false)

    return <div className={"line " + (closing ? "closing " : "") + (props.className || "")}>
        {props.children}
        {props.onClose ? <IconButton icon={<MdCancel />} onClick={() =>
        {
            setTimeout(props.onClose, 200)
            setClosing(true)
        }} /> : null}
    </div>
}

export const getOfferInputs = () => [
    <GroupSelect key="group_id" name="group_id" label={LanguageHelper.get("Group")}></GroupSelect>,
    <DateTimeInput key="order-date" name="order-date" label={LanguageHelper.get("Order_deadline")}></DateTimeInput>,
    <DateInput key="purchase-date" name="purchase-date" label={LanguageHelper.get("Purchase_date")}></DateInput>,
    <DateInput key="delivery-date" name="delivery-date" label={LanguageHelper.get("Delivery_date")}></DateInput>,
]


