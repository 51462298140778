import axios from "axios";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { InputFlag } from "../component/Form";
import LanguageHelper from "./LanguageHelper";

import store from "../store";
import { createStandaloneToast, useToast } from "@chakra-ui/react";


export const PREFIX = "kalaka_";

class KalakaAPI 
{
    settings = {};
    
    buffer = [];
    processing = false;
    debug = false;
    
    constructor()
    {
        this.process();
    }
    
    async process()
    {
        if(this.processing)
            return;
        this.processing = true;
        
        let item;
        
        while(item = this.buffer.shift())
        {            
            let request = item.request
            let callback = item.callback;
            
            await axios(request)
                .then(result => {
                    if(this.debug)
                    {
                        console.log("request", request.url, request.data)
                        console.log("result data", result.data)
                    }
                    callback(result);
                    if(result.data.sql)
                    {
                        console.log("sql query", result.data.sql)
                    }
                    item.resolve(result.data);
                })
                .catch(err => {
                    if(this.debug)
                    {
                        console.log("request data", request.data)
                    }
                    // timeout
                    if(err.message.includes("timeout"))
                    {
                        console.error("[KalakaAPI] Error at ", request.url, "\n", err);
                        this.buffer.push(item)
                        this.process()
                        return
                    }

                    let code = err.response?.status || 0;
                    if(LanguageHelper.has("error_" + code))
                    {
                        err = LanguageHelper.get("error_" + code)
                    } else 
                    {
                        err = err + ""
                    }
                    console.error("[KalakaAPI] Error at ", request.url, "\n", err);
                    callback(err);
                    item.reject(err);
                })
                
        }
        this.processing = false;
    }
    
    getUserId()
    {
        if(this.user_id) return this.user_id;
        let res;
        if(res = sessionStorage.getItem(PREFIX + "id")) return res;
        // console.warn("[Kalaka] missing user id");
        return null;
    }
    getToken()
    {
        if(this.token) return this.token;
        let res;
        if(res = sessionStorage.getItem(PREFIX + "token")) return res;
        
        // console.warn("[Kalaka] missing token");
        return null;
    }
    
    post = (target, data, callback, onerror) =>
    {
        if(!callback) callback = () => {};
        if(!data) data = {};
        
        let user_id = this.getUserId()
        let token = this.getToken()
        if(user_id && token)
        {
            data.user_id = user_id
            data.token = token
        }
        
        let resolve, reject;
        
        let promise = new Promise(function(res, rej){
            resolve = res;
            reject = rej;
        });
        
        this.buffer.push({
            resolve: resolve,
            reject: reject,
            callback: callback,
            request:
            {
                method: 'post',
                url: process.env.REACT_APP_DOMAIN + target,
                //timeout: 3000,
                headers: { 
                    'content-type': 'application/json',
                    'pragma': 'no-cache',
                    "cache-control": "no-cache"
                },
                data: data,
            }
        });
        this.process();
        
        return promise;
    }
    
    // auth
    logged_in = false;
    checkToken()
    {
        if(!this.getUserId() || !this.getToken())
        {
            console.warn("[Kalaka] missing user id or token");   
            return Promise.resolve(false);
        }
        return this.post("/api/checktoken.php")
        .then(result =>
            {
                if(result.ok)
                {
                    console.warn("[Kalaka] Accepted");
                    this.logged_in = result.ok;
                    return Promise.resolve(true);
                } else
                    return Promise.resolve(false)
            })
    }
    logOut = (needConfirm = true) =>
    {
        if(needConfirm && !window.confirm(LanguageHelper.get("confirm_logout"))) return;
        
        // delete all kalaka items from localstroage
        var keysToDelete = [];
        // Iterate over localStorage and insert the keys that meet the condition into arr
        for (var i = 0; i < localStorage.length; i++){
            if (localStorage.key(i).substring(0,7) == PREFIX && localStorage.key(i) != PREFIX + "language") {
                keysToDelete.push(localStorage.key(i));
            }
        }
        // Iterate over arr and remove the items by key
        keysToDelete.forEach(key => {
            localStorage.removeItem(key); 
        });

        this.user_id = null;
        this.token = null;

        sessionStorage.removeItem(PREFIX + "id");
        sessionStorage.removeItem(PREFIX + "token");
        
        window.location.reload();
    }
    // getters
    getLanguage = (data) => this.post("/api/LanguageHelper.php", data)
    fetchSettings = () => {
        if(this.logged_in)
        {
            this.post("/api/user/settings.php")
            .then((res) =>
            {
                this.settings = res.data;
                localStorage.setItem(PREFIX + "language", this.settings.language)
                console.log(res.data);
                return Promise.resolve(res);
            })
        }
        return Promise.resolve({})
    }
    postSettings = (settings) => this.post("/api/user/settings.php", settings)
        .then((res) =>
            {
                this.settings = res.data;
                console.log(res.data);
                return Promise.resolve(res);
            }
        )
    getSetting(key)
    {
        if(this.logged_in && this.settings)
        {
            return this.settings[key];
        } else {
            return null;
        }
    }
    getFeed = (data) =>this.post("/api/get_feed.php", data)
    groups = {};
    getGroups = () => this.post("/api/group/get_groups.php")
        .then(res => 
        {
            this.groups = {};
            res.data.forEach(group => this.groups[group.id] = group);
            // console.log(this.groups);
            return Promise.resolve(res.data)
        })
    producers = {};
    getProducers = () => this.post("/api/get_producers.php")
        .then(res => 
        {
            this.producers = {}
            res.data.forEach(producer => this.producers[producer.id] = producer)
            return Promise.resolve(res.data)
        })
    dealers = {};
    getDealers = () => this.post("/api/get_dealers.php")
        .then(res => 
        {
            this.dealers = {};
            res.data.forEach(dealer => this.dealers[dealer.id] = dealer)
            return Promise.resolve(res.data)
        })    
    getGroupName = (id) =>
    {
        let res;
        if(res = this.groups[id]?.name)
            return res;
        else 
            return "";
    }
    orders = {};
    getSale = (id) =>
    {
        if(this.orders[id])
        {
            return Promise.resolve(this.orders[id]);
        }
        return this.post("/api/get_sale.php", {sale_id: id})
            .then(res =>
            {
                this.orders[id] = res.data;
                return Promise.resolve(this.orders[id]);
            });
    }
    getOffer = (data) => this.post("/api/get_offer.php", data)
    getProduct = (data) => this.post("/api/get_product.php", data) 
    getEvent = (data) => this.post("/api/event/get_event.php", data)
    getOrder = (data) => this.post("/api/get_order.php", data);
    //getReviews = (id) => this.post("/api/get_reviews", {id: id});
    getCalendar = (data) => this.post("/api/get_calendar.php", data);
    getUser = (data) => this.post("/api/get_user.php", data)
    getGroup = (data) => this.post("/api/group/get_group.php", data)
    // setters 
    order = (data) => this.post("/api/new_order.php", data);
    offer = (data) => this.post("/api/new_offer.php", data)
    newGroup = (data) => this.post("/api/group/new_group.php", data)
    newEvent = (data) => this.post("/api/event/new_event.php", data)
    editEvent = (data) => this.post("/api/event/edit_event.php", data)
    addText = (data) => this.post("/api/new_text.php", data)
        .then(res => 
        {
            LanguageHelper.initLanguage();
            return Promise.resolve(res)
        });
    newProduct = (data) => this.post("/api/new_product.php", data)
    newProducer = (data) => this.post("/api/new_producer.php", data)
    editProduct = (data) => this.post("/api/edit_product.php", data);
    
    newEntry = (data) => this.post("/api/group/new_entry.php", data)
    enterGroup = (data) => this.post("/api/group/enter_group.php", data)
    changePassword = (data) => this.post("/api/user/change_password.php", data)
    applyEvent = (data) => this.post("/api/event/apply_event.php", data)
    doneOrder = (data) => this.post("/api/finishorder.php", data)
    review = (data) => this.post("/api/review.php", data)

    // deleters
    deleteProduct = (data) => this.post("/api/delete_product.php", data)
    deleteGroup = (data) => this.post("/api/group/delete_group.php", data)
    deleteOffer = (data) => this.post("/api/delete_offer.php", data)
    deleteAccount = (data) => this.post("/api/user/delete_account.php", data)
        .then((result) =>
        {    
            if(result.ok)
            {
                this.logOut();
                return Promise.resolve();
            }
            return Promise.reject();
        });
    
    deleteEvent = (data) => this.post("/api/event/delete_event.php", data)
    deleteOrder = (data) => this.post("/api/delete_order.php", data)
    deleteProducer = (data) => this.post("/api/delete_producer.php", data)
}

export function useAPI(props, getter, deleter)
{
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [data, setData] = useState({})
    const dispatchUpdate = () => 
    {
        getter(props).then(result => {
                console.log("[KalakaAPI]", result);
                setData(result.data || result)
                setLoading(false)
            })
            .catch(err => {
                setError(err)
                setLoading(false)
            });
    }
    const dispatchDelete = () =>
    {
        return deleter(props).catch(err => setError(err));
    } 
    useEffect(dispatchUpdate, [])
    
    return [error, loading, data, dispatchUpdate, dispatchDelete]
}

export function useEditor(props, editor)
{
    const toast = useToast()
    const [editing, setEditing] = useState(false)
    
    const onEditStart = () =>
    {
        setEditing(true)
    }
    const onEditCancel = () =>
    {
        setEditing(false)
    }
    const onEditFinish = (data) =>
    {
        return editor({...data, ...props})
        .then(res => 
            {
                setEditing(false)
                toast({
                    title: LanguageHelper.get("Done"),
                    status: "success",
                })
                return Promise.resolve(res)
            })
    }
    return [editing, onEditStart, onEditFinish, onEditCancel]
}

const { ToastContainer, toast } = createStandaloneToast()

export const messagePopup = (res) =>
{
    console.log(res)
    let message; 
    if(res.message)
        message = LanguageHelper.has("message_" + res.message) ? LanguageHelper.get("message_" + res.message) : 
            LanguageHelper.has(res.message) ? LanguageHelper.get(res.message) : res.message;
    else 
        message = res.ok ? LanguageHelper.get("Done") : LanguageHelper.get("Error");

    if(res.ok)
    {
        toast({
            title:message,
            status: "success"
        })
    } else{
        toast({
            title: message,
            status: "error"
        })
    }
    return Promise.resolve(res)
}
export default new KalakaAPI(); 

