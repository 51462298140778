import { useToast } from "@chakra-ui/react";
import React from "react";
import { MdAdd, MdOutlineCategory, MdOutlineDelete, MdOutlineDescription, MdOutlineModeEditOutline, MdOutlineMonetizationOn } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card } from "../card/Card";
import { PostContent, PostTitle } from "../card/CardComponents";
import { Separator } from "../card/Separator";
import ErrorPanel from "../component/ErrorPanel";
import { GroupLink } from "../component/GroupLink";
import { IconParameter } from "../component/IconParameter";
import { If } from "../component/If";
import PureLink from "../component/PureLink";
import { ToolButton, ToolGroup } from "../component/Tool.js";
import { UserLink } from "../component/UserLink";
import { deleteFeed, LoadingFeed } from "../Feed";
import Reviews from "../Review";
import KalakaAPI, { useAPI } from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import { setTitle } from "../util/Title";
import { GetURLParam, ToURLParams } from "../util/URLParams";


export default function Product(props)
{   
    const id = props.id || GetURLParam("id");
    const history = useHistory()
    const toast = useToast()

    const [error, loading, data, update, deleteProduct] = useAPI({product_id: id}, KalakaAPI.getProduct, KalakaAPI.deleteProduct)
    
    const onDelete = () =>
    {
        if(window.confirm(LanguageHelper.get("confirm_delete")))
        {
            deleteProduct().then(res =>
            {
                if(!res.ok)
                {
                    toast({
                        title: LanguageHelper.get("message_" + res.message),
                        status: "error"
                    })
                    
                } else {
                    deleteFeed("myproducts")
                    toast({
                        title: LanguageHelper.get("Deleted"),
                        status: "success"
                    })
                    history.goBack()
                }
            })
        }
    }
    
    if(error)
        return <ErrorPanel>{error}</ErrorPanel>;
    if(loading) 
        return (<LoadingFeed />);
    
    setTitle(data.name)
    
    return(
        <div>
            <If c={data.dealer_id === KalakaAPI.getUserId()}>
                <ToolGroup>
                    <PureLink to={"/editproduct" + ToURLParams(
                        {
                            id: data.id,
                            name: data.name,
                            category: data.category,
                            description: data.description,
                            price: data.price,
                            unitnumber: data.unitnumber,
                            unit: data.unit,
                            producer_id: data.producer_id,
                        }
                    )}>
                        <ToolButton icon={<MdOutlineModeEditOutline />} >{LanguageHelper.get("Edit")}</ToolButton>
                    </PureLink>
                    <If s={!props.offer}>
                        <ToolButton onClick={onDelete} icon={<MdOutlineDelete />}>{LanguageHelper.get("Delete")}</ToolButton>
                        <a style={{textDecoration: "none", color: "unset"}} href="#form">
                            <ToolButton icon={<MdAdd />}>{LanguageHelper.get("Offer_publish")}</ToolButton>
                        </a>
                    </If>
                </ToolGroup>
            </If>
            <Card>
                {props.contextBar}
                <PostTitle>{data.name}</PostTitle>
                <Separator />
                        
                <IconParameter icon={MdOutlineCategory}>
                    {LanguageHelper.get("category_" + data.category)}
                </IconParameter>
                <If s={data.description}>
                    <IconParameter icon={MdOutlineDescription}>
                        {data.description}
                    </IconParameter>
                </If>
                <IconParameter icon={MdOutlineMonetizationOn}>
                    {data.price}{" Ft / "} 
                    {data.unitnumber}{" "} 
                    {LanguageHelper.get(data.unit)}
                </IconParameter>
                
                <PostContent>
                        {data.producer_id > 0 ?  (<>
                            <b>{LanguageHelper.get("Producer")}</b><br></br>
                            <UserLink
                                id={data.producer_id} 
                                name={data.producer_name}
                            />
                            </>
                        )
                        : <></>}
                    <If s={data.dealer_id === KalakaAPI.getUserId()}>
                        {LanguageHelper.get("Published_to")}:
                        {data.groups.map(group => <GroupLink key={group.id} id={group.id} name={group.name} />)}
                    </If>
                </PostContent>
            </Card>
            
            
            
            {props.children}
            <Reviews id={data.review_id} reviews={data.reviews} onChange={update}/>
        </div>
    );
}
