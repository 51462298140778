import {
    Tag,
    TagLabel,
    TagLeftIcon,
    TagRightIcon,
    TagCloseButton,
} from '@chakra-ui/react'
import { MdDeviceUnknown, MdHandyman, MdHome, MdRestaurant } from 'react-icons/md'
import LanguageHelper from '../util/LanguageHelper'

export function CategoryTag(props) {
    
    return <Tag 
        m={"0.5rem"}
        size={"sm"} 
        variant='subtle' 
        borderRadius='full'
        colorScheme={props.colorScheme || "teal"}>
        <TagLeftIcon boxSize='12px' as={props.icon} />
        <TagLabel>{props.children}</TagLabel>
    </Tag>
}

export function ProductCategoryTag(props) {
    
    const content = LanguageHelper.get("category_" + props.c)
    switch (props.c) {
    case "1":
        return <CategoryTag icon={MdRestaurant} colorScheme={"green"} >{content}</CategoryTag>
    case "2":
        return <CategoryTag icon={MdHandyman} colorScheme={"blue"} >{content}</CategoryTag>
    default:
        return <CategoryTag colorScheme={"red"} >{content}</CategoryTag>
    }
}
