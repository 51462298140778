import React from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Window from '../component/Window';
import LanguageHelper from '../util/LanguageHelper';
import Form from '../component/Form';


class SetPassword extends React.Component
{
    state = {finish: false};
    constructor(props)
    {
        super(props);
        this.state.key = new URLSearchParams(window.location.search).get('k');
        this.state.user_id = new URLSearchParams(window.location.search).get('id');
        console.log(this.state.key);
    }
    submit = (data) =>
    {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_DOMAIN + "/api/user/set_password.php",
            headers: {'content-type': 'application/json'},
            data: {...data, user_id: this.state.user_id, key: this.state.key}
        })
        .then(result => { 
            console.log(result.data);
            
            if(result.data.ok)
            {
                this.setState({finish: true});
            } else 
            {
                this.setState({loading: false, message: result.data.message});
            }
            return Promise.resolve(result);
        })
        .catch(error => 
        {
            console.log(error)
            return Promise.reject(error);
        }); 
    }
    
    render = () =>
    {
        if(this.state.finish)
        {
            return (
                <Window>
                    <div style={{margin: "1rem"}}>
                        <h1 className="formtitle">{LanguageHelper.get("Done")}</h1>
                        <p><Link to="/">{LanguageHelper.get("Login")}</Link></p>
                    </div>
                </Window>
            )
        }
        
        let submit = this.state.loading ? 
        <input className="submit" type="submit" value="Mehet" disabled></input>
        : <input className="submit" type="submit" value="Mehet"></input>
        
        return (
            <Window>
                <Form onSubmit={this.submit} text={LanguageHelper.get("Enter_new_password")} inputs={
                    [
                        {id: 0, flag: LanguageHelper.get("New_password"), name: "password1", type: "password"},
                        {id: 1, flag: LanguageHelper.get("New_password_Again"), name: "password2", type: "password"},
                    ]
                }/>
            </Window>
        )
    }
}

export default SetPassword;