import { Box, Flex, Show, Spacer, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdAdd, MdCalendarToday, MdDriveFileRenameOutline, MdEvent, MdInfoOutline, MdInventory, MdLanguage, MdLogout, MdOutlineAddBusiness, MdOutlineAlternateEmail, MdOutlineBadge, MdOutlineBugReport, MdOutlineCallSplit, MdOutlineCelebration, MdOutlineDateRange, MdOutlineGroup, MdOutlineGroups, MdOutlineHandyman, MdOutlineHome, MdOutlineInfo, MdOutlineLibraryBooks, MdOutlineLocalOffer, MdOutlineMail, MdOutlineMenu, MdOutlineNotificationsActive, MdOutlinePerson, MdOutlinePhoneInTalk, MdOutlineSettings, MdOutlineShop2, MdOutlineShoppingCart, MdOutlineSwitchAccount, MdOutlineTranslate, MdStorefront, MdWorkOutline } from "react-icons/md";
import { connect } from "react-redux";
import { Link, Route, Switch, useLocation } from 'react-router-dom';

import { ScrollableRoute } from "./App.js";
import { Card } from "./card/Card.js";
import { FeedTitle, PostContent } from "./card/CardComponents.js";
import BackButton from "./component/BackButton.js";
import ErrorPanel from "./component/ErrorPanel.js";
import Form, { DateTimeInput, getOfferInputs, GroupSelect, NumberInput, ProducerSelect, Select, TextAreaInput, TextInput } from "./component/Form.js";
import HeaderButton from "./component/HeaderButton.js";
import { NavButton } from "./component/MenuButton.js";
import PureLink from "./component/PureLink.js";
import Sidebar from "./component/Sidebar.js";
import { ToolButton, ToolGroup } from "./component/Tool.js";
import Dealers from "./page/Dealers.js";
import Feed, { deleteFeed } from "./Feed.js";
import Groups from "./page/Groups.js";
import Help from "./Help.js";
import Calendar from "./page/Calendar.js";
import Event from "./page/Event.js";
import Group from "./page/Group.js";
import GroupEntry from "./page/GroupEntry.js";
import NewOffer from "./page/NewOffer.js";
import Offer from "./page/Offer.js";
import Order from "./page/Order.js";
import Producers from "./page/Producers.js";
import Product from "./page/Product.js";
import Profile from "./page/Profile.js";
import Settings, { Setting } from "./page/Settings.js";
import KalakaAPI, { PREFIX } from "./util/KalakaAPI.js";
import LanguageHelper from "./util/LanguageHelper.js";
import { Title } from "./util/Title.js";
import { GetURLParam, ToURLParams } from "./util/URLParams.js";
import { versionInfo } from "./util/Version";

export const PlaceHolder = (props) => <div style={{"height": "200px"}}></div>

const NotFound = (props) => <ErrorPanel>{LanguageHelper.get("error_404")}<br></br><Link to="/">{LanguageHelper.get("Back")}</Link></ErrorPanel>

export const backAndReload = () => 
{
    document.referrer ? window.location = document.referrer : window.history.back()
}
export const back = () =>
{
    window.history.back()
}


function Main(props)
{   
    const [offset, setOffset] = React.useState(false)
    const scrollableref = React.createRef();
    const [isMobileView] = useMediaQuery('(max-width: 1000px)')
    const location = useLocation();
    
    const user_id = KalakaAPI.getUserId();
    /*
    const getParam = (key) => new URLSearchParams(location.search).get(key);
    
    console.log(getParam("id"))
    */
   
    useEffect(() => {
        KalakaAPI.getGroups()
        KalakaAPI.getProducers()
        KalakaAPI.getDealers()
    })
    
    const setTarget = (target, state) => 
    {
        props.history.push(target);
        console.warn("Deprecated function setTarget has been called");
        return;
    }

    const getContent = () =>
    {
        return(
            <Switch>
                <Route exact path="/" >
                    <Title>{LanguageHelper.get("Home")}</Title>
                    <Feed key="main" id="main" title={LanguageHelper.get("Home")} scrollable={scrollableref} />
                </Route>
                <Route path="/group" >
                    <Title>{LanguageHelper.get("Group_posts")}</Title>
                    <Feed key="group" title={LanguageHelper.get("Group_posts")} id={"group" + GetURLParam("id")} filter_group={GetURLParam("id")}>
                        <ToolGroup>
                            <PureLink to={{pathname: "/groupdetails", search: ToURLParams({id: GetURLParam("id")})}}
                                >
                                <ToolButton icon={MdOutlineGroup}>{LanguageHelper.get("Group_details")}</ToolButton>
                            </PureLink>
                        </ToolGroup>
                    </Feed>
                </Route>  
                <Route exact path="/events" >
                    <Title>{LanguageHelper.get("Events")}</Title>
                    <Feed key="events" id="events" type="events" title={LanguageHelper.get("Events")}>
                        <ToolGroup>
                            <PureLink to={"/newevent"}>
                                <ToolButton icon={<MdAdd />} >
                                    {LanguageHelper.get("Event_publish")}
                                </ToolButton>
                            </PureLink>
                        </ToolGroup>
                    </Feed>
                </Route>
                <Route path="/sales" >
                    <Title>{LanguageHelper.get("Sales")}</Title>
                    <Feed key="sales" id="sales" type="sales" title={LanguageHelper.get("Sales")}>
                    </Feed>
                </Route>
                <Route path="/cart" >
                    <Title>{LanguageHelper.get("Cart")}</Title>
                    <Feed key="purchases" id="purchases" type="purchases" title={LanguageHelper.get("Cart")}>
                    </Feed>
                </Route>
                <Route path="/myoffers" >
                    <Title>{LanguageHelper.get("My_offers")}</Title>
                    <Feed key="myoffers" id="myoffers" type="myoffers" title={LanguageHelper.get("My_offers")}>
                        <ToolGroup>
                            <PureLink to={"/newoffer"}>
                                <ToolButton icon={<MdAdd />} >
                                    {LanguageHelper.get("Offer_publish")}
                                </ToolButton>
                            </PureLink>
                        </ToolGroup>
                    </Feed>
                </Route>
                <Route path="/market" >
                    <Title>{LanguageHelper.get("Market")}</Title>
                    <Feed key="market" id="market" type="market" title={LanguageHelper.get("Market")}>
                        <ToolGroup>
                            <PureLink to={"/newoffer"}>
                                <ToolButton icon={<MdAdd />} >
                                    {LanguageHelper.get("Offer_publish")}
                                </ToolButton>
                            </PureLink>
                        </ToolGroup>
                    </Feed>
                </Route>
                <Route path="/myproducts" >
                    <Title>{LanguageHelper.get("My_products")}</Title>
                    <Feed key="myproducts" id="myproducts" type="inventory" title={LanguageHelper.get("My_products")}>
                        <ToolGroup>
                            <PureLink to={"/newproduct"}>
                                <ToolButton icon={<MdAdd />} >
                                    {LanguageHelper.get("New_product")}
                                </ToolButton>
                            </PureLink>
                        </ToolGroup>
                    </Feed>
                </Route>
                <Route exact path="/notifications" >
                    <Feed type="notifications" nofilter title={LanguageHelper.get("Notifications")} />
                </Route>
                <Route exact path="/posts" >
                    <Title>{LanguageHelper.get("Posts")}</Title>
                    <FeedTitle>{LanguageHelper.get("Posts")}</FeedTitle>
                    <ToolGroup>
                        <PureLink to="/newpost"> 
                            <ToolButton icon={<MdAdd />} >{LanguageHelper.get("New_post")}</ToolButton>
                        </PureLink>
                    </ToolGroup>
                </Route>
                    
                {/** Feeds end here */}
                    <ScrollableRoute path="/settings" >
                        <Title>{LanguageHelper.get("Settings")}</Title>
                        <FeedTitle>{LanguageHelper.get("Settings")}</FeedTitle>
                        <Settings setTarget={setTarget} key="0">
                            <Setting key="email" type="const" name="email" icon={MdOutlineAlternateEmail} >
                                {LanguageHelper.get("Email")}
                            </Setting>
                            {/*} TODO checkbox fix
                            <Setting key="0" type="boolean" name="email_allow" icon={MediaHandler.getIcon(envelope)} >{LanguageHelper.setting_email_allow}</Setting>
                        */}
                            <Setting reload key="language" type="select" name="language" icon={MdOutlineTranslate} 
                                list={[{id:"hu", name: "Magyar"}, {id:"en", name: "English"}]}>
                                Nyelv / Language
                            </Setting>
                            <Setting key="date_format" type="select" name="date_format" icon={MdOutlineDateRange} 
                                list={
                                    [
                                        {id:"YYYY. MM. DD.", name: `${LanguageHelper.get("YYYY")}. ${LanguageHelper.get("MM")}. ${LanguageHelper.get("DD")}.`}, 
                                        {id:"YYYY/MM/DD", name: `${LanguageHelper.get("YYYY")}/${LanguageHelper.get("MM")}/${LanguageHelper.get("DD")}`},
                                        {id:"DD/MM/YYYY", name: `${LanguageHelper.get("DD")}/${LanguageHelper.get("MM")}/${LanguageHelper.get("YYYY")}`},
                                        {id:"MM/DD/YYYY", name: `${LanguageHelper.get("MM")}/${LanguageHelper.get("DD")}/${LanguageHelper.get("YYYY")}`},
                                    ]
                                }>
                                {LanguageHelper.get("Date_format")}
                            </Setting>
                            
                            <Setting key="name" type="text" name="name" icon={MdOutlineBadge}>{LanguageHelper.get("setting_displayed_name")}</Setting>
                            <Setting key="company" type="text" name="company" icon={MdWorkOutline}>{LanguageHelper.get("setting_company")}</Setting>
                            <Setting key="phone" type="text" name="phone" icon={MdOutlinePhoneInTalk}>{LanguageHelper.get("Phone_number")}</Setting>
                            
                        </Settings>
                    </ScrollableRoute>
                    <ScrollableRoute exact path="/changepassword" >
                        <Card>
                            <Title>{LanguageHelper.get("Change_password")}</Title>
                            <Form 
                                messageOnSuccess={LanguageHelper.get("Password_changed")}
                                backOnSuccess
                                key="45" text={LanguageHelper.get("Change_password")} onSubmit={KalakaAPI.changePassword} inputs={
                                [
                                    {id: 0, flag: LanguageHelper.get("Current_password"), type: "password", classnames: "short", name: "currentpassword"},
                                    {id: 1, flag: LanguageHelper.get("New_password"), type: "password", classnames: "short" , name: "newpassword"},
                                    {id: 2, flag: LanguageHelper.get("New_password_Again"), type: "password", classnames: "short" , name: "newpassword2"},
                                ]
                            }/>
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute path="/groups" >
                        <Title>{LanguageHelper.get("Groups")}</Title>
                        <Groups /> 
                    </ScrollableRoute>
                    <ScrollableRoute path="/groupdetails" >
                        <Group/>
                    </ScrollableRoute>
                    <ScrollableRoute path="/newgroup" >
                        <Card>
                            <Form  
                                messageOnSuccess={LanguageHelper.get("Group_created")}
                                backOnSuccess 
                                key="215689" text={LanguageHelper.get("New_group")} 
                                onSubmit={KalakaAPI.newGroup} >
                                <TextInput name="name" label={LanguageHelper.Group_name} />
                                <Select name="country" label={LanguageHelper.get("Country")} list={[{id: "country_hu", name: "Magyarország"}, {id: "country_au", name: "Österreich"}, {id: "country_uk", name: "United Kingdom"}]} />
                                <Select name="category" label={LanguageHelper.get("Category")} list={[
                                    {id: "grouptype_neighbourhood", name: LanguageHelper.get("grouptype_neighbourhood")}, 
                                    {id: "grouptype_family", name: LanguageHelper.get("grouptype_family")}, 
                                    {id: "grouptype_workplace", name: LanguageHelper.get("grouptype_workplace")}
                                ]} />
                                <TextInput name="description" label={LanguageHelper.get("Description")} />
                            </Form>
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute path="/entergroup" >
                        <Card>
                            <PostContent>
                                <p dangerouslySetInnerHTML={{__html: LanguageHelper.get("form_entergroup")}}></p>
                            </PostContent>
                            <Form 
                                messageOnSuccess={LanguageHelper.get("Joined_group")}
                                backOnSuccess 
                                key="2" 
                                submitText={LanguageHelper.get("Join_group")} 
                                onSubmit={KalakaAPI.enterGroup}
                                inputs={
                                    [
                                        {flag: "", type: "text" , classnames: "short", name: "entry"},
                                    ]
                                }/>
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute path="/newmember" >
                        <Card>
                            <PostContent>
                                <span dangerouslySetInnerHTML={{__html: LanguageHelper.get("form_newmember")}}></span>
                                <GroupEntry user_id={user_id}/>
                                <br/>
                            </PostContent>
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute path="/profile" >
                        <Profile key={KalakaAPI.getUserId()} />
                    </ScrollableRoute>
                    <ScrollableRoute exact path="/myprofile" >
                        <Profile key={user_id} id={user_id}/>
                    </ScrollableRoute>
                    <ScrollableRoute path="/newevent" >
                        <Card>
                            <Form
                                messageOnSuccess={LanguageHelper.get("Event_added")}
                                onSuccess={() => 
                                    {
                                        props.deleteFeed("events")
                                        props.deleteFeed("main")
                                    }}
                                    backOnSuccess 
                                    key="876542585772" 
                                    text={LanguageHelper.get("New event")}
                                    onSubmit={KalakaAPI.newEvent} 
                                    submitText={LanguageHelper.get("Create")}
                                    >
                                <TextInput name="name" label={LanguageHelper.get("Name")}></TextInput>
                                <TextInput name="description" label={LanguageHelper.get("Description")}></TextInput>
                                <TextInput name="location" label={LanguageHelper.get("Location")}></TextInput>
                                
                                <DateTimeInput name="deadline" label={LanguageHelper.get("Application_deadline")}></DateTimeInput>
                                <DateTimeInput name="event_time" label={LanguageHelper.get("Time")}></DateTimeInput>
                                <GroupSelect name="group_id" label={LanguageHelper.get("Group")}></GroupSelect>
                            </Form>
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute path="/editevent" >
                        <Card>
                            <Form
                                messageOnSuccess={LanguageHelper.get("Event_edited")}
                                onSuccess={() => 
                                    {
                                        props.deleteFeed("events")
                                        props.deleteFeed("main")
                                    }}
                                backOnSuccess 
                                key="editevent" 
                                constantInputs={{event_id: GetURLParam("id", location)}}
                                text={LanguageHelper.get("Edit event")}
                                onSubmit={KalakaAPI.editEvent} 
                                submitText={LanguageHelper.get("Save")}
                                >
                                <TextInput name="name" label={LanguageHelper.get("Name")}></TextInput>
                                <TextInput name="description" label={LanguageHelper.get("Description")}></TextInput>
                                <TextInput name="location" label={LanguageHelper.get("Location")}></TextInput>
                                
                                <DateTimeInput name="deadline" label={LanguageHelper.get("Application_deadline")}></DateTimeInput>
                                <DateTimeInput name="event_time" label={LanguageHelper.get("Time")}></DateTimeInput>
                            </Form>
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute path="/event" >
                        <Event/>
                    </ScrollableRoute>
                    <ScrollableRoute path="/sale" >
                        <Order id={GetURLParam("id")} />
                    </ScrollableRoute>
                    <ScrollableRoute path="/purchase" >
                        <Order id={GetURLParam("id")} />
                    </ScrollableRoute>
                    <ScrollableRoute path="/offer" >
                        <Offer id = {GetURLParam("id")} />
                    </ScrollableRoute>
                    <ScrollableRoute path="/newoffer" >
                        <NewOffer />
                    </ScrollableRoute>
                    <ScrollableRoute path="/newproduct" >
                        <Card>
                            <Form 
                                messageOnSuccess={LanguageHelper.get("Product_added")}
                                onSuccess={() => props.deleteFeed("myproducts")}
                                backOnSuccess
                                key="876542585232" 
                                text={LanguageHelper.get("New_product")} 
                                onSubmit={KalakaAPI.newProduct}
                            >
                                <TextInput name="name" label={LanguageHelper.get("Product_name")}/>
                                <Select name="category" label={LanguageHelper.get("Category")} list={[
                                            {id: "1", name: LanguageHelper.get("category_1")},
                                            {id: "2", name: LanguageHelper.get("category_2")},
                                            {id: "3", name: LanguageHelper.get("category_3")},
                                            {id: "4", name: LanguageHelper.get("category_4")},
                                            {id: "5", name: LanguageHelper.get("category_5")},
                                            {id: "6", name: LanguageHelper.get("category_6")},
                                        ]}>
                                </Select>
                                <TextAreaInput name="description" label={LanguageHelper.get("Description")}/>
                                <ProducerSelect name="producer_id" label={LanguageHelper.get("Producer")} />
                                <NumberInput name="unitnumber" label={LanguageHelper.get("Order_unit")}/>
                                <Select name="unit" label={LanguageHelper.get("Unit")} list={[
                                        {id: "unit_pcs", name: LanguageHelper.get("unit_pcs")},
                                        {id: "unit_gramm", name: LanguageHelper.get("unit_gramm")},
                                        {id: "unit_decagram", name: LanguageHelper.get("unit_decagram")},
                                        {id: "unit_kilogram", name: LanguageHelper.get("unit_kilogram")},
                                        {id: "unit_milliliter", name: LanguageHelper.get("unit_milliliter")},
                                    ]}>
                                </Select>
                                <NumberInput name="price" label={LanguageHelper.get("Price") + " (Ft)"}></NumberInput>
                            </Form>
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute path="/editproduct" >
                        <Card>
                            <Form 
                                messageOnSuccess={LanguageHelper.get("Product_edited")}
                                onSuccess={() => props.deleteFeed("myproducts")}
                                backOnSuccess
                                text={LanguageHelper.get("Edit_product")} 
                                onSubmit={KalakaAPI.editProduct}
                                constantInputs={{product_id: GetURLParam("id", location)}}
                            >
                                <TextInput name="name" label={LanguageHelper.get("Product_name")}/>
                                <Select name="category" label={LanguageHelper.get("Category")} list={[
                                            {id: "1", name: LanguageHelper.get("category_1")},
                                            {id: "2", name: LanguageHelper.get("category_2")},
                                            {id: "3", name: LanguageHelper.get("category_3")},
                                            {id: "4", name: LanguageHelper.get("category_4")},
                                            {id: "5", name: LanguageHelper.get("category_5")},
                                            {id: "6", name: LanguageHelper.get("category_6")},
                                        ]}>
                                </Select>
                                <TextAreaInput name="description" label={LanguageHelper.get("Description")}/>
                                <ProducerSelect name="producer_id" label={LanguageHelper.get("Producer")} />
                                <NumberInput name="unitnumber" label={LanguageHelper.get("Order_unit")}/>
                                <Select name="unit" label={LanguageHelper.get("Unit")} list={[
                                        {id: "unit_pcs", name: LanguageHelper.get("unit_pcs")},
                                        {id: "unit_gramm", name: LanguageHelper.get("unit_gramm")},
                                        {id: "unit_decagram", name: LanguageHelper.get("unit_decagram")},
                                        {id: "unit_kilogram", name: LanguageHelper.get("unit_kilogram")},
                                        {id: "unit_milliliter", name: LanguageHelper.get("unit_milliliter")},
                                    ]}>
                                </Select>
                                <NumberInput name="price" label={LanguageHelper.get("Price") + " (Ft)"}></NumberInput>
                            </Form>
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute path="/product" >
                        <Product>
                            <Card>
                                <Form key="68479865" 
                                    messageOnSuccess={LanguageHelper.get("Published")}
                                    text={LanguageHelper.get("Offer_publish")} 
                                    submitText={LanguageHelper.get("Create")}
                                    onSubmit={data => KalakaAPI.offer({...data, product_id: GetURLParam("id")})}
                                    >
                                    {getOfferInputs()}
                                </Form>
                            </Card>
                        </Product>
                    </ScrollableRoute>
                    <ScrollableRoute noplaceholder path="/calendar" >
                        <Title>{LanguageHelper.get("Calendar")}</Title>
                        <Calendar/>
                    </ScrollableRoute>
                    <ScrollableRoute path="/myproducers" >
                        <Title>{LanguageHelper.get("My_producers")}</Title>
                        <Producers id="myproducers" />
                    </ScrollableRoute>
                    <ScrollableRoute path="/newproducer" >
                        <Title>{LanguageHelper.get("Add_producer")}</Title>
                        <Card>
                            <Form 
                                messageOnSuccess={LanguageHelper.get("Producer_added")}
                                backOnSuccess 
                                onSuccess={KalakaAPI.getProducers} 
                                key="68479855" 
                                text={LanguageHelper.get("Add_producer")} 
                                onSubmit={KalakaAPI.newProducer} 
                            >
                                <TextInput name="email" label={LanguageHelper.get("Producer_email")}/>
                            </Form> 
                                
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute exact path="/mydealers" >
                        <Title>{LanguageHelper.get("My_dealers")}</Title>
                        <Dealers id="mydealers" />
                    </ScrollableRoute>
                    {/** TODO what the hell is this */}
                    <ScrollableRoute exact path="/dealeroffer" >
                        <Offer id = {GetURLParam("id")} />
                    </ScrollableRoute>
                    <ScrollableRoute exact path="/feedback" >
                        <Card>
                            <Form
                                text={LanguageHelper.get("Feedback")} 
                                messageOnSuccess={LanguageHelper.get("Sent")}
                                backOnSuccess
                                key="15648513215849" onSubmit={(d) => KalakaAPI.post("/api/betafeedback.php", d)} 
                                inputs={
                                [
                                    {id: "1", flag: "Kategória", type: "select" , name: "category", classnames: "short", list:
                                    [
                                        {id: "Hiba (bug)", name: "Hiba (bug)"},
                                        {id: "Értékelés", name: "Értékelés"},
                                        {id: "Ötlet", name: "Ötlet"},
                                        {id: "Egyéb", name: "Egyéb"},
                                    ]
                                    },
                                    {id: "3", flag: "Releváns oldal", type: "text" , name: "location"},
                                    {id: "2", flag: "Leírás", type: "textarea" , name: "desc"},
                                ]
                            }/>
                            
                        </Card>
                    </ScrollableRoute>
                    <ScrollableRoute exact path="/deleteaccount" >
                        <Title>{LanguageHelper.get("Delete_account")}</Title>
                        <Card>
                            <Form key="66" text={LanguageHelper.get("Delete_account")} onSubmit={(v, callback) => {KalakaAPI.deleteAccount(v, callback)}} inputs={
                                [
                                    {id: 0, flag: LanguageHelper.get("Password"), type: "password", classnames: "short", name: "password"},
                                    {id: 1, flag: LanguageHelper.get("Why_delete_account"), type: "textarea", classnames: "", name: "why"},
                                ]
                            }/>
                        </Card>
                    </ScrollableRoute>

                    <Route >
                        <NotFound />
                    </Route>

            </Switch>
        )
    }
    
   
    
    const render = () =>
    {   
        return (<>
            <Box 
                position={"sticky"} 
                onClick={resetOffset} 
                bgColor={"#2ba359"} 
                color={"white"}>
                
                <Flex m="auto" w={"100%"} maxWidth={"calc(600px - 1rem)"}>
                    <Show breakpoint='(max-width: 1000px)'>
                        <HeaderButton
                            visible={isMobileView}
                            as={MdOutlineMenu}
                            onClick={() => setOffset("left")}/>
                    </Show>
                    <BackButton onClick={() => back()}/>
                    
                    <Spacer />
                    {/*<Logo /> */}
                    <Spacer />
                    
                    <Help />
                    
                    <Show breakpoint='(max-width: 1000px)'>
                        <HeaderButton 
                            visible={isMobileView}
                            as={MdOutlinePerson}
                            onClick={() => setOffset("right")}/>
                    </Show>
                </Flex>
            </Box>
            
            <div id="sheet" onClick={() => setOffset(null)}/>
                
            <Flex h={"calc(100% - 3rem)"} justifyContent={"center"}>
                
                <Sidebar onAnyNavClick={() => setOffset("")} isMobileView={isMobileView} side={"left"} openSide={offset}>
                    <NavButton to="/" icon={MdOutlineHome}>{LanguageHelper.get("Home")}</NavButton>
                    <NavButton to="/info" icon={MdInfoOutline}>{LanguageHelper.get("Info")}</NavButton>
                    <NavButton to="/events" icon={MdOutlineCelebration}>{LanguageHelper.get("Events")}</NavButton>
                    <NavButton to="/market" icon={MdStorefront}>{LanguageHelper.get("Market")}</NavButton>
                    <NavButton to="/cart" icon={MdOutlineShoppingCart}>{LanguageHelper.get("Cart")}</NavButton>
                    <NavButton to="/sales" icon={MdOutlineShop2}>{LanguageHelper.get("Sales")}</NavButton>
                    <NavButton to="/myoffers" icon={MdOutlineLibraryBooks}>{LanguageHelper.get("My_offers")}</NavButton>
                    <NavButton to="/myproducts" icon={MdInventory}>{LanguageHelper.get("My_products")}</NavButton>
                    <NavButton to="/myproducers" icon={MdOutlineAddBusiness}>{LanguageHelper.get("My_producers")}</NavButton>
                    <NavButton to="/mydealers" icon={MdOutlineSwitchAccount}>{LanguageHelper.get("My_dealers")}</NavButton>
                </Sidebar>
                
                <Box w={"100%"} maxW={"600px"} onClick={resetOffset}>
                    {/** clear prevoius title */}
                    <Title></Title>
                    {getContent()}
                </Box>
                
                <Sidebar onAnyNavClick={() => setOffset("")} isMobileView={isMobileView} side={"right"} openSide={offset}>
                    <NavButton to="/calendar" icon={MdOutlineDateRange}>{LanguageHelper.get("Calendar")}</NavButton>
                    <NavButton to="/notifications" icon={MdOutlineNotificationsActive}>{LanguageHelper.get("Notifications")}</NavButton>
                    <NavButton to="/groups" icon={MdOutlineGroup}>{LanguageHelper.get("Groups")}</NavButton>
                    <NavButton to="/myprofile" icon={MdOutlinePerson}>{LanguageHelper.get("User")}</NavButton>
                    <NavButton to="/settings" icon={MdOutlineSettings}>{LanguageHelper.get("Settings")}</NavButton>
                    

                    <NavButton to="/reportbug" icon={MdOutlineBugReport}>{LanguageHelper.get("Report_bug")}</NavButton>

                    <NavButton 
                        onClick={() => KalakaAPI.logOut(true)} 
                        icon={MdLogout}>
                            {LanguageHelper.get("Log_out")}
                    </NavButton>
                    
                    <Box position={"absolute"} bottom={"0"} m={"1rem"} fontWeight={700}>
                        <p>Kaláka v{versionInfo.version} {versionInfo.target}</p>
                        <>
                            <p><Link to="languageconfig">{LanguageHelper.get("Language_config")}</Link></p>
                            <p><Link to="feedback">{LanguageHelper.get("Feedback")}</Link></p>
                        </>
                    </Box>
                </Sidebar>
                
            </Flex>
        </>
        );
    }
    const resetOffset = () => {
        if(offset) 
            setOffset(false)
    }
    return render()
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteFeed: (key) => deleteFeed(key),
        setPopup: (children) => deleteFeed(children)
    }
};
export default connect(null, mapDispatchToProps)(Main)

