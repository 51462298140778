import { useEffect, useRef, useState } from "react";


import InfiniteScroll from 'react-infinite-scroll-component';

import PureLink from "./component/PureLink.js";
import KalakaAPI from "./util/KalakaAPI";
import LanguageHelper from "./util/LanguageHelper";

import { Box, Button, Spinner } from "@chakra-ui/react";
import { MdArrowUpward } from "react-icons/md";
import { Scrollable } from "./App.js";
import { FeedText, FeedTitle } from "./card/CardComponents";
import { EventCard } from "./card/EventCard";
import { OfferCard } from "./card/OfferCard.js";
import { ProductCard } from "./card/ProductCard.js";
import { PurchaseCard } from "./card/PurchaseCard.js";
import { SaleCard } from "./card/SaleCard.js";
import ErrorPanel from "./component/ErrorPanel";
import { useFilterBar, useSearchBar } from "./component/FilterBar";
import { If } from "./component/If.js";
import { PlaceHolder } from "./Main.js";
import { deletePersistentState, usePersistentState } from "./util/PersistentState";
import { NotificationCard } from "./card/NotificationCard.js";

let dScroll = 0, lastScroll = 0

export default function Feed(props)
{
    const initialRender = useRef(true);
    const [filterBar, sortBy, order] = useFilterBar(props.id);
    const [searchBar, search] = useSearchBar(props.id);
    
    const [loading, setLoading] = usePersistentState("feed", props.id, "loading", true);
    const [feed, setFeed] = usePersistentState("feed", props.id, "feed", []);
    const [scroll, setScroll] = usePersistentState("feed", props.id, "scroll", 0);
    const [offset, setOffset] = usePersistentState("feed", props.id, "offset", 0);
    
    const [hasMore, setHasMore] = useState(true);
    const [err, setError] = useState(null);
    
    let scrollableRef = useRef(null);
    let startTime = Date.now();

    
    async function getFeed (reset = false)
    {
        try {
            const result = await KalakaAPI.getFeed({
                browse_start: startTime,
                offset: reset ? 0 : offset,
                type: props.type || "",
                filter_group: props.filter_group,
                filter_user: props.filter_user,
                filter_name: search,
                sort_by: sortBy,
                order: order,
            });
            setOffset((reset ? 0 : offset) + 10);

            if (result.data.length < 10)
                setHasMore(false);
            if (result.data.length > 0) {
                setFeed((reset ? [] : feed).concat(result.data));
            }
            setLoading(false);
        } catch (err) {
            return setError(err);
        }
    }
    function resetFeed()
    {   
        console.log("reset feed", props.id)
        setLoading(true);
        setFeed([])
        setScroll(0);
        scrollTo(0)
        setOffset(0)
        setHasMore(true)
        getFeed(true)
    }

    const scrollTo = (pos) =>
    {
        if(scrollableRef.current)
        {
            scrollableRef.current.scrollTo(0, pos)
        }
    }
    // when search changes, but not on initial render
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            resetFeed()
        }
    }, [search, sortBy, order]);
    
    useEffect(() => {
       getFeed()
    }, [])
    
    useEffect(() => {
        scrollTo(scroll)
    }, [scrollableRef.current])
    
    const onScroll = (e) =>
    {
        const scrollTop = scrollableRef.current.scrollTop
        dScroll = scrollTop - lastScroll;
        lastScroll = scrollTop;
        setScroll(scrollTop);
    }
    let component = {
        sale: SaleCard,
        purchase: PurchaseCard,
        offer: OfferCard,
        myoffer: OfferCard,
        myproduct: ProductCard,
        event: EventCard,
        notification: NotificationCard,
    }
    let content = feed.map((item, i) => 
        {
            const Item = component[item.type];
            
            let to = props.to ? {pathname: props.to, search: "?id="+item.id} : null
            if(!Item)
            {
                console.warn("unknown feed type", item.type);
                return null;
            }
            
            let content = <Item 
                key={item.type+"_"+item.id+"_"+item.group_id} 
                id={item.id}
                {...item}
            ></Item>
            
            if(to)
                return <PureLink to={to}>
                    {content}
                </PureLink>
            else 
                return content;
        })
    
    const head = <>
        <FeedTitle>
            {props.title}
            
        </FeedTitle>
        {props.children /**toolbar*/}
        {filterBar} 
        {searchBar}
    </>
    if(loading)
    {
        return <div>
            {head}
            <LoadingFeed />
            <PlaceHolder />
        </div>
    } else if(err)
    {
        return <div>
            {head}
            <ErrorPanel >{err}</ErrorPanel>
            <PlaceHolder />
        </div>
    } else if(feed.length === 0)
    {
        return <div>
            {head}
            <FeedText>{LanguageHelper.get("Empty_list")}</FeedText>
            <PlaceHolder />
        </div>
    } else 
        return <Scrollable Ref={scrollableRef} onScroll={onScroll}>
            <InfiniteScroll
                scrollableTarget={"scrollable"}
                dataLength={feed.length}
                next={getFeed}
                hasMore={hasMore}
                loader={<LoadingFeed />}
            >
                <Box h="100%" overflowY={"overlay"} >
                    <JumpButton hidden={dScroll >= 0 || scroll < 100} onClick={resetFeed}/>
                    
                    {head}
                    {content}
                    
                    <If c={!hasMore}>
                        <FeedText>
                            {LanguageHelper.get("Reached_end")}
                        </FeedText>
                    </If>
                    
                    <If c={hasMore}>
                        <PlaceHolder />
                    </If>
                </Box>
            </InfiniteScroll>
            
        </Scrollable>
        
}
Feed.defaultProps = {
    type: "",
    sortBy: ""
};

export const deleteFeed = (id) => {
    deletePersistentState("feed", id, "feed")
    deletePersistentState("feed", id, "offset")
    deletePersistentState("feed", id, "scroll")
    deletePersistentState("feed", id, "loading")
}

const JumpButton = (props) => <div 
    className={"JumpButton" + (props.hidden ? " hidden" : "")}
>
    <Button leftIcon={<MdArrowUpward />} onClick={props.onClick} colorScheme="blue">
        {LanguageHelper.get("Jump_to_top")}
    </Button>
</div>

export const LoadingFeed = (props) => <Box w={"100%"} textAlign="center" p={"2rem"}>
    <Spinner/>
</Box>


