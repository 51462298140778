import { createSlice } from '@reduxjs/toolkit';
import { dateToDatabaseString } from './util/DateHelper';

export const CalendarSlice = createSlice({
    name: 'calendar',
    initialState: {
        date: dateToDatabaseString(new Date()),
        loading: true,
        events: []
    },
    reducers: {
        setDate(state, action)
        {
            state.date = action.payload
        },
        setLoading(state, action)
        {
            state.loading = action.payload
        },
        setEvents(state, action)
        {
            state.events = action.payload
        }
    },
})

export const {setDate, setLoading, setEvents} = CalendarSlice.actions

export default CalendarSlice.reducer