import { extendTheme } from "@chakra-ui/react";

export const globalTheme = extendTheme({
    components: {
        Popover: {
          variants: {
            responsive: {
                content: {
                    width: 'fit-content',
                    maxWidth: 'unset',
                },
                popper: {
                }
            }
          }
        },
        Button: {
            baseStyle: {
                background: 'white',
                borderRadius: '0.2rem',
            }
        }
    },
    colors: {
        background: '#f0f0f0',
        primary: '#f2f4f2',
        minor: 'rgb(72, 80, 146)',
        secondary: 'var(--secondarycolor)',
    },
    shadows: {
        shadow: 'var(--shadow)',
    },
    fonts: {
        body: "Roboto",
    },
    styles: {
        global: {
            body: {
                bg: 'var(--secondarycolor)',
                color: 'white',
            },
            a: {
                textDecoration: 'revert',
            },
            button: {
                fontWeight: '700',
            }
        },
    },
})