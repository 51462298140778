import { useState } from "react"
import LanguageHelper from "../util/LanguageHelper"

import { usePersistentState } from "../util/PersistentState"
import { Box } from "@chakra-ui/react"
import { ToolButton, ToolGroup } from "./Tool"
import { MdClear, MdSearch } from "react-icons/md"
import {FaSortAlphaDown, FaSortAlphaDownAlt, FaSortNumericDown, FaSortNumericDownAlt} from "react-icons/fa"
import { If } from "./If"

const useSortBy = (id, defaultSortBy) => {
    const [sort, setSortBy] = usePersistentState("filter", id, "sortBy", defaultSortBy || "time");
    return [
        sort, 
        setSortBy
    ]
}
const useOrder = (id, defaultOrder) => {
    const [order, setOrder] = usePersistentState("filter", id, "order", defaultOrder || "desc");
    return [
        order, 
        setOrder
    ]
}
export const useFilterTools = (id, defaultSortBy, defaultOrder) => {
    const [sortBy, setSortBy] = useSortBy(id, defaultSortBy);
    const [order, setOrder] = useOrder(id, defaultOrder);

    return [sortBy, setSortBy, order, setOrder]
}

export const useFilterBar = (id, defaultSortBy, defaultOrder, options) => {
    const [sortBy, setSortBy, order, setOrder] = useFilterTools(id, defaultSortBy, defaultOrder);

    const filterBar = <FilterBar
        options={options}   
        sort={sortBy}
        order={order}
        onSortChange={(sort, order) => {
            setSortBy(sort)
            setOrder(order)
        }}
    />
    return [filterBar, sortBy, order]
}

const FilterIcons = {
    "timedesc": <FaSortNumericDownAlt />,
    "timeasc": <FaSortNumericDown />,
    "namedesc": <FaSortAlphaDownAlt />,
    "nameasc": <FaSortAlphaDown />
}

export const FilterBar = (props) => { 
    let options = props.options || [
        ["time","desc"], 
        ["time","asc"], 
        ["name","asc"], 
        ["name", "desc"]
    ];
    
    options = options.map((option) => {
        return [option[0], option[1], FilterIcons[option[0]+option[1]]]
    })
    
    return <ToolGroup>
        {options.map(([sort, order, icon]) =>
            <ToolButton 
                key={sort+order}
                active={props.sort === sort && props.order === order} 
                icon={icon}
                onClick={() => 
                {
                    props.onSortChange(sort, order)
                }} 
            />
        )}
        
    </ToolGroup>
}

const useSearch = (id) => {
    const [search, setSearch] = usePersistentState("search", id, "", "");
    return [
        search, 
        setSearch
    ]
}
export const useSearchBar = (id) => {
    const [search, setSearch] = useSearch(id);
    const [tempSearch, setTempSearch] = useState(search)
    
    return [
        <ToolGroup >
            <Box ml={"1rem"}>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    setSearch(tempSearch)
                }}>
                    <input value={tempSearch} 
                        className="searchInput"
                        placeholder={LanguageHelper.get("Search") + "..."}
                        onChange={(e) => setTempSearch(e.target.value)}      
                    ></input>
                    <If c={tempSearch.length > 0}>
                        <ToolButton 
                            icon={<MdClear />}
                            onClick={() => 
                                {
                                    setSearch("")
                                    setTempSearch("")
                                }}
                            ></ToolButton>
                    </If>
                    <ToolButton icon={MdSearch} onClick={() => setSearch(tempSearch)}/>
                </form>
            </Box>
        </ToolGroup>,
        search
    ]
}