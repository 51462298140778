import React from "react";
import "./CheckMark.css";

class CheckMark extends React.Component
{
    state = {
        checked: false,
        loading: false
    }
    constructor(props){
        super(props);
        if(props.checked !== undefined)
        {
            this.state.checked = props.checked
        }
        if(props.value !== undefined)
        {
            this.state.value = props.value === "1"
        }
    }
    change = (e, checked) =>
    {
        e.stopPropagation()

        if(this.state.loading) return;
        
        this.setState({loading: true});
        
        if(this.props.onChange)
        {
            this.props.onChange({
                checked: checked ? "1" : "0", // swap
                id: this.props.id
            }).then(result => {
                if(result.ok)
                    this.setState({checked: !this.state.checked, loading: false});
                else 
                    this.setState({loading: false});
            });
        }
    }
    render = () =>
    {
        let checkbox;
        if(this.state.loading)
            checkbox = (
                <div className="checkmarkcontainer load">
                    <input type="checkbox" ref={this.props.reference} style={{display:"none"}} readOnly value="1"></input>
                    <div className="checkmark true"></div>
                </div>);
        else if(this.state.checked)
            checkbox = (
                <div className="checkmarkcontainer true" onClick={(e) => this.change(e, false)}>
                    <input type="checkbox" ref={this.props.reference} style={{display:"none"}} readOnly value="1"></input>
                    <div className="checkmark true"></div>
                </div>
            );
        else 
            checkbox = (
                <div className="checkmarkcontainer false" onClick={(e) => this.change(e, true)}>
                    <input type="checkbox" ref={this.props.reference} style={{display:"none"}} readOnly value="0"></input>
                    <div className="checkmark false"></div>
                </div>
            );
        
        return <> 
            <span style={{float: this.props.right ? "right" : "none"}}>
                {checkbox}
            </span>
            {this.props.label ? <div className="checkmarklabel">{this.props.label}</div> : null}
            
        </>
    }
}

export default CheckMark;