import React from "react";
import { MdAdd, MdOutlineLogin } from "react-icons/md";
import { Card } from "../card/Card";
import { FeedTitle, PostDescription, PostTitle } from "../card/CardComponents";
import ErrorPanel from "../component/ErrorPanel";
import { useFilterBar, useSearchBar } from "../component/FilterBar";
import PureLink from "../component/PureLink";
import { ToolButton, ToolGroup } from "../component/Tool";
import { LoadingFeed } from "../Feed";
import KalakaAPI, { useAPI } from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";

export default function Groups(props)
{
    const [error, loading, groups] = useAPI({}, KalakaAPI.getGroups)
    
    const [searchBar, search] = useSearchBar("groups")
    const [filterBar, sortBy, order] = useFilterBar("groups", "name", "asc", [["name", "asc"], ["name", "desc"]]);
    
    if(error)
        return <ErrorPanel></ErrorPanel>
    if(loading)
        return <LoadingFeed />
    
    let empty;
    if(groups.length === 0)
    {
        empty = <Card><PostDescription>{LanguageHelper.get("Empty_list")}</PostDescription></Card>;    
    }
    return <>
        <FeedTitle>
            {LanguageHelper.get("Groups")}
        </FeedTitle>
        <ToolGroup>
            <PureLink to="/newgroup">
                <ToolButton icon={<MdAdd />} >{LanguageHelper.get("New_group")}</ToolButton>
            </PureLink>
            <PureLink to="/entergroup">
                <ToolButton icon={<MdOutlineLogin />} >{LanguageHelper.get("Enter_to_group")}</ToolButton>
            </PureLink>
        </ToolGroup>
        {filterBar}
        {searchBar}
        {empty}
        {
            groups
            .filter(group => group.name && group.name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => (a[sortBy] > b[sortBy]) === (order === "asc") ? 1 : -1)
            .map((group) =>
            {
                return <PureLink key={group.id} to={{pathname: "groupdetails", search: "?id="+group.id}}>
                    <Card>
                        <PostTitle>{group.name} {"(" + group.member_count + ")"}</PostTitle>
                    </Card>
                </PureLink>
            })
            
        }
    </>
}

                    
                    
                       