import { IconButton, useToast } from "@chakra-ui/react";
import React from "react";
import { MdAdd, MdCalendarToday, MdLocationPin, MdOutlineDelete, MdOutlineDescription, MdOutlineEdit, MdOutlineRepeat } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card } from "../card/Card";
import { ContextBar, PostDescription, PostTitle } from "../card/CardComponents";
import { RelativeDeadline } from "../component/RelativeDeadline";
import { Separator } from "../card/Separator";
import CheckMark from "../component/CheckMark";
import ErrorPanel from "../component/ErrorPanel";
import { GroupLink } from "../component/GroupLink";
import PureLink from "../component/PureLink";
import { ToolButton, ToolGroup } from "../component/Tool";
import { TypeLink } from "../component/TypeLink";
import { UserLink } from "../component/UserLink";
import { deleteFeed, LoadingFeed } from "../Feed";
import { renderDateString, stringToDate } from "../util/DateHelper";
import KalakaAPI, { useAPI } from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import { GetURLParam, ToURLParams } from "../util/URLParams";
import { IconParameter } from "../component/IconParameter";

export default function Event(props)
{
    const id = props.id || GetURLParam("id");
    
    const toast = useToast()
    
    const dispatch = useDispatch()
    const history = useHistory()
    const [error, loading, data, update, deleteEvent] = useAPI({event_id: id}, KalakaAPI.getEvent, KalakaAPI.deleteEvent)
    
    const onDelete = () =>
    {
        if(window.confirm(LanguageHelper.get("confirm_delete")))
        {
            deleteEvent().then((res) => {
                if(!res.ok)
                {
                    toast({
                        title: LanguageHelper.get("message_0"),
                        status: "error"
                    })
                    return;
                }
                deleteFeed("events")
                deleteFeed("main")
                toast({
                    title: LanguageHelper.get("Deleted"),
                    status: "success"
                })
                history.goBack()
            })
        }
    }
    const onApply = d => 
    {
        return KalakaAPI.applyEvent({...d, event_id: data.id})
        .then(res =>
            {
                update();
                if(res.ok) 
                    toast({
                        title: d.checked === "1" ? LanguageHelper.get("Applied") : LanguageHelper.get("Withdrawn"),
                        status: "success"
                    })
                return Promise.resolve(res);
            }
        )
    }

    if(loading)
        return <LoadingFeed></LoadingFeed>
    if(error)
        return <ErrorPanel>{error}</ErrorPanel>
    return <div>
        {data.user_id === KalakaAPI.getUserId() ?
            <ToolGroup> 
                <PureLink to={"/newevent" + ToURLParams(
                    {
                        name: data.name,
                        description: data.description,
                        location: data.location,
                        group_id: data.group_id
                    }) 
                }>
                    <ToolButton icon={MdOutlineRepeat}>
                        {LanguageHelper.get("Repeat_event")}
                    </ToolButton>
                </PureLink>
                <ToolButton icon={MdOutlineDelete} onClick={onDelete}>
                    {LanguageHelper.get("Delete")}
                </ToolButton> 
                <PureLink to={"/editevent" + ToURLParams( 
                    {
                        id: data.id,
                        name: data.name,
                        description: data.description,
                        location: data.location,
                        deadline: data.deadline,
                        event_time: data.time,
                    })
                }>
                    <ToolButton icon={MdOutlineEdit}>
                        {LanguageHelper.get("Edit")}
                    </ToolButton>   
                </PureLink>
            </ToolGroup>
        : null}
        
        <Card>    
            <ContextBar>
                <TypeLink to="/events">{LanguageHelper.get("Event")}</TypeLink>
                <UserLink name={data.user_name} id={data.user_id}></UserLink>
                <GroupLink name={data.group_name} id={data.group_id}></GroupLink>
            </ContextBar>
            <PostTitle>
                {data.name}
            </PostTitle>
            
            <Separator />
            
            <IconParameter icon={MdOutlineDescription}>{data.description}</IconParameter>
            {data.location ? (<IconParameter icon={MdLocationPin}>{data.location}</IconParameter>) : null}
            <IconParameter icon={MdCalendarToday}>{renderDateString(data.time)}</IconParameter>

            <Separator />
            <div className="half">
                <RelativeDeadline date={data.deadline}>{LanguageHelper.get("Application_deadline")}</RelativeDeadline>
            </div>
            <div className="half">
                {stringToDate(data.deadline) > new Date() ?
                    <CheckMark
                        checked={data.applied_users.filter(elem => elem.id == KalakaAPI.getUserId()).length > 0}
                        onChange={onApply} 
                        label={LanguageHelper.get("Apply")}
                    />
                : null}
            </div>
        </Card>
        <Card>
            <PostTitle>
                {LanguageHelper.get("Applied_members")}
            </PostTitle>
            {
                data.applied_users?.length == 0 ? 
                <PostDescription>{LanguageHelper.get("Empty_list")}</PostDescription>
                : data.applied_users?.map(user => <UserLink key={user.id} id={user.id} name={user.name}></UserLink>)
            }
        </Card>
    </div>
}