import { Avatar, Button, WrapItem } from "@chakra-ui/react"
import LanguageHelper from "../util/LanguageHelper"
import { ToURLParams } from "../util/URLParams"
import PureLink from "./PureLink"

export function UserLink(props)
{
    const button = <Button 
        borderRadius={".2rem"} 
        pl={".8rem"} 
        bgColor={"primary"}
        leftIcon={<WrapItem><Avatar size={"xs"} name={props.name}/></WrapItem>} 
        onClick={props.onClick} >
            {props.name}{props.admin && " (" + LanguageHelper.get("Administrator") + ")" }
    </Button>
    if(props.inactive)
        return button
    return <PureLink to={"/profile"+ToURLParams({id: props.id})}>
        {button}
    </PureLink>
}