import React from "react";
import { useHistory } from "react-router-dom";
import {IoMdArrowRoundBack} from "react-icons/io"
import HeaderButton from "./HeaderButton";

function BackButton(props)
{
    let history  = useHistory()
    return <HeaderButton
        as={IoMdArrowRoundBack} 
        onClick={() => history.goBack()}/>
}

export default BackButton;