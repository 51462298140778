import { IconButton } from "@chakra-ui/react";

export default function HeaderButton(props)
{
    return <IconButton 
        {...props} 
        cursor={"pointer"}  
        w="2rem" h="2rem" m=".5rem"
        bgColor="transparent"
        _hover={{bgColor: "rgba(255,255,255,0.2)"}}
    />
}