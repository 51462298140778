import React from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import LanguageHelper from '../util/LanguageHelper';
import Window from '../component/Window';
import Form from '../component/Form';

export default class Register extends React.Component
{
    state = {
        loading: false,
        finish: false
    }
    constructor(props)
    {
        super(props);
        
        this.path = process.env.REACT_APP_DOMAIN + "/api/register.php";
    }
    submit = (data, callback) =>
    {
        axios({
            method: 'post',
            url: this.path,
            headers: {'content-type': 'application/json'},
            data: data
        })
        .then(result => { 
            console.log(result.data);
            if(result.ok)
            {
                this.setState({finish: true});
            } else 
            {
                this.setState({loading: false, message: result.data.message, wronginputs: result.data.wronginputs});
            }
            callback(result.data);
        })
        .catch(error => console.log(error));     
        
    }
    render = () =>
    {
        if(this.state.finish)
        {
            return (
                <Window>
                    <div style={{margin: "1rem"}}>
                        <h1 className="formtitle">Már majdnem kész</h1>
                        <p>A befejezéshez nyisd meg a levelet, amit a megadott e-mail címre küldtünk.</p>
                    </div>
                </Window>
            )
        }
        
        return (
        <Window>
            <Form onSubmit={this.submit} text={LanguageHelper.get("Register")} submitText={LanguageHelper.get("button_next")} inputs={
                [
                    {id: 0, flag: LanguageHelper.get("Email"), type: "text", name: "email"},
                    {id: 1, flag: LanguageHelper.get("Username"), type: "text", name: "name"},
                ]
            } />
            <div style={{margin: "1rem"}}>
                <p><Link to="/">{LanguageHelper.get("Already_have_an_account")}</Link></p>
            </div>
        </Window>
        )
    }
}