import React from 'react';
import Icon from "../Icon";

import axios from "axios";
import LanguageHelper from '../util/LanguageHelper.js';
import { Link } from 'react-router-dom';
import Form, { PasswordInput, TextInput } from '../component/Form.js';
import Window from '../component/Window';
import { PREFIX } from '../util/KalakaAPI.js';
import { MdLogin } from 'react-icons/md';

export default function Login(props)
{
    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    
    const nameRef = React.useRef();
    const passwordRef = React.useRef();
    
    const path = process.env.REACT_APP_DOMAIN + "/api/login.php";
    
    const submit = (data) =>
    {
        return axios({
            method: 'post',
            url: path,
            headers: {'content-type': 'application/json'},
            data: data
        })
        .then(result => {
            let data = result.data;
            console.log(data);
            if(data.ok)
            {
                sessionStorage.setItem(PREFIX + "id", data.id);
                sessionStorage.setItem(PREFIX + "token", data.token);
                // reload to check token
                window.location.reload()
            }
            return Promise.resolve(result);
        })
        .catch(error => 
        {
            console.log(error);
            return Promise.reject(error);
        });
    }
    const render = () =>
    {   
        return (
            <Window>
                <Form onSubmit={submit} icon={<MdLogin />} text={LanguageHelper.get("Login")} submitText={LanguageHelper.get("Login")}>
                    <TextInput 
                        label={LanguageHelper.get("Username") + " / " + LanguageHelper.get("Email")} 
                        name={"name"}></TextInput>
                    <PasswordInput
                        label={LanguageHelper.get("Password")} 
                        name={"password"}></PasswordInput>
                </Form>
                <div style={{margin: "1rem"}}>
                    <p><Link to="/register">{LanguageHelper.get("Create_account")}</Link></p>
                    <p><Link to="/forgotpassword">{LanguageHelper.get("Forgot_password")}</Link></p>
                </div>
            </Window>
        )
    }
    return render()
}
