import React from "react";
import KalakaAPI from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import { GetURLParam } from "../util/URLParams";

class GroupEntry extends React.Component
{
    state = {
        entry: "------",
    }
    constructor(props)
    {
        super(props);
        this.state.groupid = GetURLParam("id");
        this.getEntry();
    }
    getEntry = () =>
    {
        KalakaAPI.newEntry(this.state)
        .then(result => { 
            //console.log(result.data);
            if(result.ok)
            {
                this.setState({entry: result.data.entry});
            } else 
            {
                this.setState({entry: "", message: result.data.message});
            }
        });
    }
    render = () =>
    {
        return (
            <div>
                <div style={
                    {
                        "padding": "1rem",
                        "textAlign": "center",
                        "fontSize":"3rem",
                        "fontFamily":"consolas",
                        "user-select": "text",
                    }
                }>
                    <b>
                        {this.state.entry}
                    </b>
                </div>
            </div>
        );
    }
}

export default GroupEntry;