import React from "react";
import { ToURLParams } from "../util/URLParams";
import { GroupLink } from "../component/GroupLink";
import PureLink from "../component/PureLink";
import { TypeLink } from "../component/TypeLink";
import { UserLink } from "../component/UserLink";
import { ContextBar, PostBar, PostDescription, PostTitle, PriceTag } from "./CardComponents";
import { TimeBar } from "./CardComponents";
import LanguageHelper from "../util/LanguageHelper";
import { Card } from "./Card";
import { RelativeDeadline } from "../component/RelativeDeadline";
import { CATEGORY, CategoryTag, ProductCategoryTag } from "../component/CategoryTag";

export const OfferCard = React.memo((props) =>
{
    let data = props;
    
    return <Card>
        <ContextBar>
            <TypeLink to="/market">{LanguageHelper.get("Offer")}</TypeLink>
            <UserLink name={data.dealer_name} id={data.dealer_id}/>
            <GroupLink name={data.group_name} id={data.group_id}/>
        </ContextBar>
        <PureLink to={{pathname: "/offer", search: ToURLParams({id: data.id})}}> 
            <PostBar>
                <PostTitle>
                    {data.product_name}
                    <PriceTag price={data.price} unit={data.unit} unitnumber={data.unitnumber}/>
                    <ProductCategoryTag c={data.category}/>
                </PostTitle>
                <TimeBar time={data.time} m="-.6rem 0 0 1rem"/>
                <PostDescription>
                    {data.description}
                </PostDescription>
                <RelativeDeadline date={data.order_deadline}>
                    {LanguageHelper.get("Order_deadline")}
                </RelativeDeadline>
            </PostBar>
        </PureLink>
    </Card>
})