import React from "react";
import { GroupLink } from "../component/GroupLink";
import PureLink from "../component/PureLink";
import { TypeLink } from "../component/TypeLink";
import { UserLink } from "../component/UserLink";
import { RelativeDate } from "../component/RelativeDate";
import LanguageHelper from "../util/LanguageHelper";
import { Card } from "./Card";
import { ContextBar, PostBar, PostDescription, PostTitle, TimeBar } from "./CardComponents";
import { ToURLParams } from "../util/URLParams";

export const PurchaseCard = React.memo((props) =>
{
    let data = props;
    return <Card>
            <ContextBar>
                <TypeLink to="/cart">{LanguageHelper.get("Order")}</TypeLink> 
                <UserLink name={data.dealer_name} id={data.dealer_id}/>
                <GroupLink name={data.group_name} id={data.group_name}/>
            </ContextBar>
            <PureLink to={"/purchase" + ToURLParams({id: data.id})}>
                <PostBar>
                    <PostTitle>
                        {data.product_name}
                    </PostTitle>
                    <TimeBar time={data.time} m="-.6rem 0 0 1rem"/>
                    <PostDescription>
                        {data.amount} {LanguageHelper.get( data.unit )}{" - "}{data.price * data.amount} ft
                    </PostDescription>
                    <RelativeDate dateOnly date={data.delivery_date}>{LanguageHelper.get("Delivery_date")}</RelativeDate>   
                </PostBar>
            </PureLink>
        </Card>
})