import React from "react";
import { Card } from "../card/Card";
import { FeedTitle, PostContent } from "../card/CardComponents";
import ErrorPanel from "../component/ErrorPanel";
import Feed, { LoadingFeed } from "../Feed";
import KalakaAPI, { useAPI } from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import { setTitle } from "../util/Title";
import { GetURLParam } from "../util/URLParams";
import { IconParameter } from "../component/IconParameter";
import { MdOutlineAlternateEmail, MdOutlinePhoneInTalk, MdWorkOutline } from "react-icons/md";
import { Avatar, Box, Flex, Text } from "@chakra-ui/react";

export default function Profile(props)
{
    const id = props.id || GetURLParam("id")
    const [error, loading, data] = useAPI({id: id}, KalakaAPI.getUser)

    setTitle(data.name)
    
    if(loading)
        return (<LoadingFeed></LoadingFeed>)
    if(error)
        return <ErrorPanel>{error}</ErrorPanel>
    
    return (
        <div>
            <FeedTitle>    
                {LanguageHelper.get("User")}
            </FeedTitle>
            <Card>
                <PostContent>
                    <Flex flexDirection={"row"} flexWrap={"wrap"}>
                        <Flex mb={"1rem"} flex={1} >
                            <Avatar name={data.name} display="inline-block" mr={".8rem"} mb={".8rem"}></Avatar>
                            <Flex userSelect={"text"} display={"inline-block"} mr={"1rem"}>
                                <Text><big><b>{data.name}</b></big></Text>
                                <p>@{data.username}</p>
                            </Flex>
                        </Flex>
                        <Box userSelect={"text"} flex={1}>
                            <IconParameter icon={MdOutlineAlternateEmail}>{data.email || "-"}</IconParameter>
                            <IconParameter icon={MdOutlinePhoneInTalk}>{data.phone}</IconParameter>
                            <IconParameter icon={MdWorkOutline}>{data.company}</IconParameter>
                        </Box>
                    </Flex>
                </PostContent>
            </Card>

            <Feed id={"user_" + id} filter_user={id}>
                <FeedTitle>    
                    {LanguageHelper.get("Users_posts")}
                </FeedTitle>
            </Feed>
        </div>
    )
}