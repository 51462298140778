import { Box, Button, Icon, IconButton } from "@chakra-ui/react";
import React from "react";

export function ToolButton(props)
{
    const defaultProps = {
        onClick: props.onClick,
        bgColor: "primary",
        borderRadius: ".2rem",
    }
    
    const icon = typeof props.icon === "object" ? props.icon.type : props.icon;
    
    if(props.children)
    {
        return <Button
            {...defaultProps}
            pl={".5rem"}
            leftIcon={<Icon as={icon} w={"1.5rem"} h={"1.5rem"} />}
            >
            {props.children}
        </Button>
    } else {
        return <IconButton
            {...defaultProps}
            icon={<Icon as={icon} w={"1.5rem"} h={"1.5rem"} />}
        />
    }
    
}

export function ToolGroup(props)
{
    return <Box
        display={"inline-flex"}
        maxW={"calc(100% - 2rem)"}
        flexWrap={"wrap"}
        verticalAlign={"middle"}
        w={"fit-content"}
        m={"1rem 0 0 1rem"}
        borderRadius={".2rem"}
        bgColor={"primary"}
        shadow={"md"}
    >
        {props.children}
    </Box>
}