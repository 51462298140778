import React from "react";
import "../css/Help.css";
import LanguageHelper from "./util/LanguageHelper";
import Overlay from "./component/Overlay";
import { MdHelpOutline } from "react-icons/md";
import HeaderButton from "./component/HeaderButton";
import { Box, Text } from "@chakra-ui/react";
import { If } from "./component/If";

class Help extends React.Component
{
    state = {
        active: false,
    }
    
    render = () =>
    {
        let path = window.location.pathname.substring(1)

        let text = LanguageHelper.get("help_" + path)
        if(typeof text === "string")
        {
            text = text.replaceAll("\n", "<br></br>")
            text = text.replaceAll("\\n", "<br></br>")
        }
        
        const button = <HeaderButton 
            onClick={() => this.setState({active: true})} as={MdHelpOutline} />
            
        if(this.state.active)
        {
            return (<>
                {button}
                <div>
                    <Overlay 
                        open={true}
                        onClose={() => this.setState({active: false})}
                        style={{"maxWidth": "400px"}}
                        >
                        <Box m="1rem">
                            <Text mb="1rem" fontSize={"larger"} fontWeight="700">{LanguageHelper.get("Help") + (this.props.title ? " - " + this.props.title : "")}</Text>
                            <If c={typeof text === "string"}>
                                <div dangerouslySetInnerHTML={{__html: text}} />
                            </If>
                            <If c={typeof text !== "string"}>
                                {text}
                            </If>
                        </Box>
                    </Overlay>
                        
                </div>
            </>);
        } else
        return button
    }
}

export default Help;