import React from "react";
import ReactDOM from 'react-dom';

import { IconButton } from "@chakra-ui/react";
import { MdClear } from "react-icons/md";
import "../../css/Overlay.css";
import { If } from "./If";

export default function Overlay(props)
{
    if(props.open)
        return ReactDOM.createPortal(
            <div className="Overlay" onClick={props.onClose}>
                <div className={"OverlayContent " + (props.invisible ? "invisible" : "")} style={props.style} onClick={(e) => e.stopPropagation()}> 
                    <If c={!props.hideCloseButton}>
                        <div className="CloseButton">
                            <IconButton bgColor={"var(--secondary)"} icon={<MdClear />} onClick={props.onClose}/>
                        </div>
                    </If>  
                    {props.children}
                </div>
            </div>,  
            document.getElementById('overlay')
        )
    else return null
}




