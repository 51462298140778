import { Button } from "@chakra-ui/react";
import { MdOutlineGroup } from "react-icons/md";
import { ToURLParams } from "../util/URLParams";
import PureLink from "./PureLink";

export function GroupLink(props)
{
    return <PureLink to={"/group"+ToURLParams({id: props.id})}>
        <Button 
            borderRadius={".2rem"} 
            pl={".5rem"} 
            leftIcon={<MdOutlineGroup />} 
            onClick={props.onClick} 
            bgColor={"primary"}
            >{props.name}</Button>
    </PureLink>
}