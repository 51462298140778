import React from "react";
import PureLink from "../component/PureLink.js";
import LanguageHelper from "../util/LanguageHelper";

export const NotificationJoinedCard = React.memo((props) => {
    let data = props;
    return <PureLink to={"/groupdetails?id=" + data.group_id}>
        {LanguageHelper.get("notification_joined", { subject_name: data.subject_name, group_name: data.group_name })}
    </PureLink>;
});
