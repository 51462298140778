import { Box, Flex, Icon, Text } from "@chakra-ui/react";

export function IconParameter(props)
{
    return (
        <Flex mb="1rem" mt="1rem" ml=".8rem" h={"fit-content"}>
            <Icon as={props.icon} display={"inline-block"} w={"1.4rem"} h={"1.4rem"} mr={".5rem"}/>
            <Box display={"inline-block"} verticalAlign="top">
                {props.children}
            </Box>
        </Flex>
    )
}