import { Box } from "@chakra-ui/react";

export default function Sidebar(props)
{
    return <Box 
        h={"calc(100% - 3rem)"} 
        zIndex={10} 
        bgColor={"secondary"} 
        transition={".5s"} 
        flex={"0 0 200px"} 
        w={"200px"} 
        position={props.isMobileView ? "absolute" : "static"} 
        left={props.side === "left" ? props.openSide !== props.side ? "-200px" : "0px" : "unset"}
        right={props.side === "right" ? props.openSide !== props.side ? "-200px" : "0px" : "unset"}
        >
            {props.children.map((element, index) => 
                <element.type 
                    key={index} 
                    onClick={props.onAnyNavClick} 
                    {...element.props} 
                />)}
    </Box>  
}