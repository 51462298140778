import { Box } from "@chakra-ui/react";
import React from "react";
import { Card } from "../card/Card";

export default function Window(props)
{
    return <Box w="25rem" m="auto">
        <Card>
            {props.children}
        </Card>
    </Box>
}