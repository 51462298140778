
export function GetURLParam(key, location)
{
    const res = new URLSearchParams((location || new URL(document.URL)).search).get(key);
    return res;
}
export function ToURLParams(obj)
{
    let res = "?";
    Object.entries(obj).forEach(([key, value]) => res += key + "=" + value + "&");
    return res;
}