import axios from "axios";
import React from "react";

import { render } from "react-dom";
import { Link } from "react-router-dom";

import Form from "../component/Form";
import LanguageHelper from "../util/LanguageHelper";
import Window from "../component/Window";


export default function ForgotPassword (props)
{
    const [done, setDone] = React.useState(false);
    
    const submit = (data) =>
    {
        return axios({
            method: 'post',
            url: process.env.process.env.REACT_APP_DOMAIN + "/api/user/forgot_password.php",
            headers: {'content-type': 'application/json'},
            data: data
        })
        .then(result => { 
            console.log(result.data);
            if(result.data.ok)
                setDone(true)
            return Promise.resolve(result);
        })
        .catch(error => console.log(error));   
    }
    render = () =>
    {
        if(done)
        return <Window padding>
            <p>
                {LanguageHelper.get("form_forgot_password_end")}
            </p>
            <Link to="/">{LanguageHelper.get("button_next")}</Link>
        </Window>
        return <Window>
            <Form onSubmit={submit} text={LanguageHelper.get("Forgot_password")} inputs={
                [
                    {id: 0, flag: LanguageHelper.get("Email"), type: "text", name: "email"},
                ]
            }/>
        </Window>
    }
    return render()
}