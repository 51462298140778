
export function setTitle(title)
{
    if(typeof title === "string")
        document.title = title + " - Kaláka" 
    else 
        document.title = "Kaláka"
}

export const Title = (props) => 
{
    setTitle(props.children)
    return ""
}
