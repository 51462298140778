import { Card } from "../card/Card";
import { FeedText, FeedTitle, PostDescription, PostTitle } from "../card/CardComponents";
import { useFilterBar, useSearchBar } from "../component/FilterBar";
import PureLink from "../component/PureLink";
import KalakaAPI from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import { ToURLParams } from "../util/URLParams";


export default function Dealers() { 
    const [searchBar, search] = useSearchBar("mydealers")
    const [filterBar, sortBy, order] = useFilterBar("mydealers", "name", "asc", [["name", "asc"], ["name", "desc"]]);

    const list = Object.values(KalakaAPI.dealers)
        .filter(dealer => dealer.name && dealer.name.toLowerCase().includes(search.toLowerCase()))
        .sort((a, b) => (a[sortBy] > b[sortBy]) === (order === "asc") ? 1 : -1)
        .map(dealer => (
        <PureLink key={"dealerlink" + dealer.id} to={"/profile" + ToURLParams({id: dealer.id})}>
            <Card>
                <PostTitle>
                    {dealer.name}
                </PostTitle>
                <PostDescription>
                    {dealer.company}
                </PostDescription>
            </Card>
        </PureLink>
    ))
            
    const head = <>
        <FeedTitle>
            {LanguageHelper.get("My_dealers")}
        </FeedTitle>
        {filterBar}
        {searchBar}
    </>
    
    if(list.length === 0)
        return <>
            {head}
            <FeedText>{LanguageHelper.get("Empty_list")}</FeedText>
        </>
    else return <>
        {head}
        {list}
    </>
        
}