import { Box, useToast } from "@chakra-ui/react";
import { MdAdd, MdCalendarToday, MdDelete, MdOutlineWork, MdWork, MdWorkOutline } from "react-icons/md";

import { Card } from "../card/Card";
import { FeedText, FeedTitle, PostContent, PostDescription, PostTitle } from "../card/CardComponents";
import ErrorPanel from "../component/ErrorPanel";
import { useFilterBar, useSearchBar } from "../component/FilterBar";
import { IconParameter } from "../component/IconParameter";
import { If } from "../component/If";
import OptionsMenu from "../component/OptionsMenu";
import PureLink from "../component/PureLink";
import { ToolButton, ToolGroup } from "../component/Tool.js";
import { UserLink } from "../component/UserLink";
import { LoadingFeed } from "../Feed";
import KalakaAPI, { useAPI } from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import { renderDateString } from "../util/DateHelper";

export default function Producers(props)
{
    const toast = useToast()
    const [error, loading, producers, update] = useAPI(null, KalakaAPI.getProducers)
    const [searchBar, search] = useSearchBar("myproducers")
    const [filterBar, sortBy, order] = useFilterBar("myproducers", "name", "asc", [["name", "asc"], ["name", "desc"], ["time", "asc"], ["time", "desc"]]);

    if(error)
        return <ErrorPanel>{error}</ErrorPanel>
    if(loading)
        return <LoadingFeed />

    return <>
        <FeedTitle>
            {LanguageHelper.get("My_producers")}
        </FeedTitle>
        <ToolGroup>    
            <PureLink to={"newproducer"}>
                <ToolButton icon={<MdAdd />} >
                    {LanguageHelper.get("Add_producer")}
                </ToolButton>
            </PureLink>
        </ToolGroup>
        {filterBar}
        {searchBar}
        {
            Object.entries(producers).length === 0 ? 
                <FeedText>{LanguageHelper.get("Empty_list")}</FeedText>
            :
            Object.values(producers)
                .filter(producer => producer.name?.toLowerCase().includes(search.toLowerCase()))
                .sort((a, b) => (a[sortBy] > b[sortBy]) === (order === "asc") ? 1 : -1)
                .map(producer => (
                    <Card key={producer.id}>
                        <UserLink id={producer.id} name={producer.name} />

                        <OptionsMenu>
                            <ToolButton icon={MdDelete} onClick={() => 
                                KalakaAPI.deleteProducer({producer_id: producer.id})
                                .then(update)
                                .then(() => toast({title: LanguageHelper.get("Deleted"), status: "success"}))
                            }>
                                {LanguageHelper.get("Delete")}
                                </ToolButton>
                        </OptionsMenu>
                        <If c={producer.company}>
                            <IconParameter icon={MdWorkOutline}>
                                {producer.company}
                            </IconParameter>
                        </If>
                        <If c={producer.time != null}>
                            <IconParameter icon={MdCalendarToday}>
                                {renderDateString(producer.time, false)}
                            </IconParameter>
                        </If>
                    </Card>
            ))
        }
    </>
}