import KalakaAPI from "./KalakaAPI";

export function stringToDate(dateString: string)
{
    let error = false
    let arr = dateString.split(/[- :]/);
    let year = parseInt(arr[0])
    if(year < 1970)
    {
        error = true
        year = 1970
    }
    let month = parseInt(arr[1]) - 1 
    if(month < 0)
    {
        error = true
        month = 0
    }
    let day = parseInt(arr[2])
    if(day < 1)
    {
        error = true
        day = 1
    }
    let hour = parseInt(arr[3]) || 0
    let minute = parseInt(arr[4]) || 0
    let second = parseInt(arr[5]) || 0
    
    /*
    if(error)
        console.log("[Date] invalid date ", dateString) 
    */
    //console.log("stringToDate", year, month, day, hour, minute, second)
    return new Date(year, month /* starts with 0*/, day, hour, minute, second);
}

function zeroPad(n: number, length: number = 2): string
{
    let res = n + "";
    while(res.length < length)
        res = "0" + res
    return res
}

function dateToString(date: Date, hasTime: boolean = true): string
{
    let res = KalakaAPI.getSetting("date_format") || "YYYY. MM. DD.";
    res = res.replace("YYYY", date.getFullYear())
    res = res.replace("MM", zeroPad(date.getMonth() + 1))
    res = res.replace("DD", zeroPad(date.getDate()))
    
    if(hasTime)
        return res + ` ${date.toLocaleTimeString()}`
    else 
        return res
}

export function renderDateString(dateString: string, hasTime: boolean = true)
{
    if(!dateString)
        return ""
    return dateToString(stringToDate(dateString), hasTime)
}

// YYYY-MM-DD HH:MM:SS
export function dateToDatabaseString(date: Date)
{
    return date.getFullYear() + "-" + zeroPad(date.getMonth() + 1) + "-" + zeroPad(date.getDate()) + " " + zeroPad(date.getHours()) + ":" + zeroPad(date.getMinutes()) + ":" + zeroPad(date.getSeconds())
}
