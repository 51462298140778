import React, { useState } from "react";
import { MdLogout, MdOutlineArticle, MdOutlineDelete, MdOutlinePersonAdd, MdPersonAddAlt1 } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { Card } from "../card/Card";
import { FeedTitle, PostDescription, PostTitle } from "../card/CardComponents";
import ErrorPanel from "../component/ErrorPanel";
import PureLink from "../component/PureLink";
import { ToolButton, ToolGroup } from "../component/Tool.js";
import { UserLink } from "../component/UserLink";
import { LoadingFeed } from "../Feed";
import { renderDateString } from "../util/DateHelper";
import KalakaAPI, { messagePopup, useAPI } from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import { setTitle } from "../util/Title";
import { GetURLParam, ToURLParams } from "../util/URLParams";
import { IconParameter } from "../component/IconParameter";
import { IconButton, useToast } from "@chakra-ui/react";
import OptionsMenu from "../component/OptionsMenu";
import { If } from "../component/If";


export default function Group(props)
{
    const group_id = props.id || GetURLParam("id");
    const history = useHistory()
    const [error, loading, data, update, deleteGroup] = useAPI({group_id: group_id}, KalakaAPI.getGroup, KalakaAPI.deleteGroup) 
    
    const [member, setMember] = useState(null)
    
    const leave = () =>
    {
        if(!window.confirm(LanguageHelper.get("dialogue_leave_group"))) return;
        
        KalakaAPI.post("/api/group/leave_group.php", {group_id: group_id})
            .then(messagePopup)
            .then(result => result.ok && history.goBack());
        
    }
    const promote = (id) =>
    { 
        if(!window.confirm(LanguageHelper.get("dialogue_confirm"))) return;
       
        KalakaAPI.post("/api/group/promote.php", {id: id, groupid: group_id})
            .then(messagePopup)
            .then(update)
    }
    const kick = (id) =>
    {
        if(!window.confirm(LanguageHelper.get("dialogue_confirm"))) return;
        KalakaAPI.post("/api/group/kick.php", {id: id, groupid: group_id})
            .then(messagePopup)
            .then(update)
    }
    const onDelete = () =>
    {
        if(window.confirm(LanguageHelper.get("confirm_delete")))
        {
            return deleteGroup()
                .then(messagePopup)
                .then(res => res.ok && history.goBack())
        }
    }
    
    setTitle(data.name)
    if(loading)
        return (<LoadingFeed />);
    if(error)
        return <ErrorPanel>{error}</ErrorPanel>
        
    let adminbuttons = data.admin ? [
            <PureLink to={"newmember" + ToURLParams({"id": group_id})}>
                <ToolButton icon={MdOutlinePersonAdd} >{LanguageHelper.get("Add_member")}</ToolButton>
            </PureLink>,
            <ToolButton icon={MdOutlineDelete} onClick={onDelete} >{LanguageHelper.get("Delete_group")}</ToolButton>
        ] : [];
            
    return (<div className="">
        <FeedTitle>{LanguageHelper.get("Group_details")}</FeedTitle>
        <OptionsMenu m="-2rem 1rem 0 0 ">
            {[...adminbuttons,
                <ToolButton icon={MdLogout} onClick={leave}>{LanguageHelper.get("Leave_group")}</ToolButton>
            ]}
        </OptionsMenu>
        <ToolGroup>
            <PureLink to={"/group" + ToURLParams({id: group_id})}>
                <ToolButton icon={MdOutlineArticle}>{LanguageHelper.get("Group_posts")}</ToolButton>
            </PureLink>
        </ToolGroup>
        <Card>
            <PostTitle>
                {data.name}
            </PostTitle>
            <PostDescription>
                <p>{LanguageHelper.get(data.category)}</p>
            </PostDescription>
            {data.desc ? <IconParameter >{data.desc}</IconParameter> : null}
            <IconParameter >{LanguageHelper.get("Created_at")}{": "}{renderDateString(data.date, false)}</IconParameter>
        </Card>  
        <Card>
            <PostTitle>
                {LanguageHelper.get("Members")} {"(" + data.members.length + ")"} 
            </PostTitle>
            {(data.members || []).map(member => 
                <div key={member.id} style={{"width":"100%", "height":"3rem"}}>
                    <UserLink 
                        key={member.id}
                        id={member.id}
                        name={member.name} 
                        admin={parseInt(member.admin) === 1}>
                    </UserLink>
                    <If c={parseInt(data.admin) === 1 && parseInt(member.admin) !== 1 }>
                        <OptionsMenu>
                            <ToolButton icon={MdPersonAddAlt1} onClick={() => promote(member.id)}>{LanguageHelper.get("Promote")}</ToolButton>
                            <ToolButton icon={MdOutlineDelete} onClick={() => kick(member.id)}>{LanguageHelper.get("Kick")}</ToolButton>
                        </OptionsMenu>
                    </If>
                </div>
            )}
            
        </Card>
        
    </div>);
}