import React from "react";
import PureLink from "../component/PureLink.js";
import LanguageHelper from "../util/LanguageHelper";
import { If } from "../component/If.js";
import { ContextBar, PostBar, PostTitle, PostDescription, PostContent } from "./CardComponents";
import { RelativeDeadline } from "../component/RelativeDeadline";
import { RelativeDate } from "../component/RelativeDate";
import { Card } from "./Card";
import { TypeLink } from "../component/TypeLink.js";
import { UserLink } from "../component/UserLink.js";
import { GroupLink } from "../component/GroupLink.js";
import { IconParameter } from "../component/IconParameter.js";
import { MdDone } from "react-icons/md";

export const EventCard = React.memo((props) => {
    let data = props;
    return <Card>
        <ContextBar>
            <TypeLink to="/events">{LanguageHelper.get("Event")}</TypeLink>
            <UserLink name={data.user_name} id={data.user_id}></UserLink>
            <GroupLink name={data.group_name} id={data.group_id}></GroupLink>
        </ContextBar>
        <PureLink to={"/event?id=" + props.id}>
            <PostBar>
                <PostTitle>
                    {data.event_name}
                </PostTitle>
                <PostDescription>
                    {data.event_description}
                </PostDescription>
                <If c={data.applied === "1"}>
                    <IconParameter icon={MdDone}>{LanguageHelper.get("You_applied")}</IconParameter>
                </If>
                <If c={data.applied !== "1"}>
                    <RelativeDeadline date={data.deadline}>{LanguageHelper.get("Application_deadline")}</RelativeDeadline>
                </If>
                <RelativeDate date={data.event_time}>{LanguageHelper.get("Time")}</RelativeDate>
            </PostBar>
        </PureLink>
    </Card>;
});
