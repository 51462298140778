import React from "react";
import { Card } from "./Card";
import { PostContent } from "./CardComponents";
import { NotificationInvitedYouCard } from "./NotificationInvitedYouCard";
import { NotificationJoinedCard } from "./NotificationJoinedCard";
import { NotificationKickedYouCard } from "./NotificationKickedYouCard";
import { NotificationLeftCard } from "./NotificationLeftCard";
import { NotificationOfferDeleteCard } from "./NotificationOfferDeleteCard";
import { NotificationPasswordChangeCard } from "./NotificationPasswordChangeCard";

export const NotificationCard = React.memo((props) => {
    //return "notification"
    let data = props;

    const components = {
        password_change: NotificationPasswordChangeCard,
        joined: NotificationJoinedCard,
        left: NotificationLeftCard,
        added_you: NotificationInvitedYouCard,
        kicked_you: NotificationKickedYouCard,
        offer_delete: NotificationOfferDeleteCard
    };
    let Type = components[data.notification_type];
    if (!Type)
        return <Card>
            <PostContent>
                Failed to load notification
            </PostContent>
        </Card>;

    return <Card>
        <PostContent>
            <Type {...data} />
        </PostContent>
    </Card>;
});
