import { Button } from "@chakra-ui/react";
import PureLink from "./PureLink";

export function TypeLink(props)
{
    return <PureLink to={props.to}>
        <Button 
            borderRadius={".2rem"} 
            bgColor={"primary"}
            fontWeight={700}
        >{props.children}</Button>
    </PureLink>
}
