import React, { Component } from "react";
import { IconButton } from "@chakra-ui/react";

import Form, { ScoreInput, TextAreaInput } from "./component/Form";

import KalakaAPI from "./util/KalakaAPI";
import LanguageHelper from "./util/LanguageHelper";
import { If } from "./component/If";
import { Card } from "./card/Card";
import { FeedTitle, PostDescription, SmallTitle, TimeBar } from "./card/CardComponents";
import { UserLink } from "./component/UserLink";
import { MdOutlineEdit } from "react-icons/md";
import { ToolButton } from "./component/Tool";
/**
 * props: id
 */
export default class Reviews extends Component
{
    render = () =>
    {
        let own_review = (this.props.reviews || []).filter(review => review.user_id === KalakaAPI.getUserId())[0];
        let reviews =(this.props.reviews || []).filter(review => review.user_id !== KalakaAPI.getUserId());
        
        return <>
            <FeedTitle>
                {LanguageHelper.get("Reviews")}
            </FeedTitle>
            <Card>
                <ReviewForm onChange={this.props.onChange} review_id={this.props.id} {...own_review}/>
                {reviews.map(review => <Review key={review.user_id} {...review}></Review>)}
            </Card> 
        </>
    }
}
/**
 * props: user_id, user_name, score, comment
 */
export class Review extends Component
{
    render = () =>
    {
        let score = "";
        for(let i = 0; i < this.props.score; i++)
            score += "★";
        for(let i = this.props.score; i < 5; i++)
            score += "☆";
            
        return <>
            <UserLink id={this.props.user_id} name={this.props.user_name}></UserLink><br/>
            <PostDescription>
                <div style={{margin: "-1rem 2rem 0 1.75rem"}}>
                    <p><b>{score}</b></p>
                    <TimeBar time={this.props.time} />
                    <p><i>{this.props.comment}</i></p>
                </div>
            </PostDescription>
           
        </>
    }
}

class ReviewForm extends Component
{
    state={editing: false}
    constructor(props)
    {
        super(props);
        this.state.editing = props.score ? false : true;
    }
    
    send = (data) => KalakaAPI.review({...data, review_id: this.props.review_id})    
        .then((res) => 
        {
            if(res.ok)
            {
                this.props.onChange();
                this.setState({editing: false});
            } 
            return Promise.resolve(res); 
        });
    
    render = () =>
    {
        return <>
            <If s={this.state.editing}>
                <Form submitText={LanguageHelper.get("Done")} onSubmit={this.send}>
                    <ScoreInput defaultValue={this.props.score || 5} name="score" ></ScoreInput>
                    <TextAreaInput defaultValue={this.props.comment || ""} name="comment" label={LanguageHelper.get("Comment")}></TextAreaInput>
                </Form>
            </If>
            <If s={!this.state.editing}>
                <ToolButton onClick={() => this.setState({editing: true})} icon={MdOutlineEdit}>{LanguageHelper.get("Edit")}</ToolButton><br />
                <Review {...this.props}></Review>
            </If> 
        </>
        
    }
}