import { Box } from "@chakra-ui/react";

export const Card = (props) => (
    <Box 
        m="1rem"
        bgColor={"primary"}
        shadow={"md"}
        borderRadius={".2rem"}
        //borderTop={".1px transparent solid"}
        overflow={"hidden"}
        >
        {props.children}
    </Box>
);
