import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Card } from './card/Card';
import Form, { TextAreaInput, TextInput } from './component/Form';
import { If } from './component/If';
import { LoadingFeed } from './Feed';
import Main, { PlaceHolder } from './Main';
import ForgotPassword from './page/ForgotPassword';
import Login from './page/Login';
import Register from './page/Register';
import SetPassword from './page/SetPassword';
import KalakaAPI, { PREFIX } from './util/KalakaAPI';
import LanguageHelper, { LanguageConfig } from './util/LanguageHelper';
import { deleteAllCache } from './util/PersistentState';

function FullWidth(props)
{
    return <div style={{width: "100%"}}>{props.children}</div>
}
export const Scrollable = (props) => <div 
    id="scrollable" 
    ref={props.Ref} 
    onScroll={props.onScroll}
    style={{overflowY: "overlay", height: "calc(100%)"}}>
        {props.children}
    </div>
    
export const ScrollableRoute = (props) => 
    <Route {...props}> 
        <Scrollable>
            {props.children}
            <If s={!props.noplaceholder}>
                <PlaceHolder />
            </If>
        </Scrollable>
    </Route>
    
export const URLParamContext = React.createContext({});

export default function App()
{
    const [b, setB] = React.useState(false);

    useEffect(() =>
    {
        // deleteAllCache()
    })
    const navigate = (path) =>
    {
        window.location.replace(path);
    }
    const deleteParams = () =>
    {
        // delete url params
        window.history.pushState({}, document.title, window.location.pathname);
    }
    
    return <>
        <Switch>
            <Route path="/languageconfig">
                <LanguageConfig />
            </Route>
            <Route path="/addtext" >
                <FullWidth>
                    <Card>
                        <Form
                            text={"Enter language constant"}
                            onSubmit={KalakaAPI.addText}
                            submitText={LanguageHelper.get("Create")}
                            >
                            <TextInput name="key" label="key"></TextInput>
                            <TextAreaInput name="hu" label="hu"></TextAreaInput>
                            <TextAreaInput name="en" label="en"></TextAreaInput>
                        </Form>
                    </Card>
                </FullWidth>
            </Route>
            
            <Route path="/register" >
                <Register />
            </Route>
            <Route path="/activate" render={() =>
            {
                let key = new URLSearchParams(window.location.search).get('k');
                let user_id = new URLSearchParams(window.location.search).get('id');
                if(!key || !user_id) 
                {
                    navigate("/");
                    return;
                }
                return <SetPassword/>;
            }} />
            <Route path="/forgotpassword">
                <ForgotPassword/>
            </Route>
            
            <Route path="/">
                <LoginHandler>
                    <LoggedOut>
                        <Login/>
                    </LoggedOut>
                    <LoggedIn>
                        <Main refresh={() => setB(true)} />
                    </LoggedIn>
                </LoginHandler>
            </Route>
        </Switch>
    </>
}

function LoginHandler(props)
{
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    
    useEffect(() =>
    {
        KalakaAPI.checkToken()
        .then(ok => {
            if(ok)
            {   
                setLoading(false)
                setLoggedIn(true);
            } else
            {
                setLoading(false);
                setLoggedIn(false);
            }
        })
    })
    
    if(loading)
        return <LoadingFeed />
    else if(loggedIn)
        return props.children.filter(child => child.type === LoggedIn)
    else 
        return props.children.filter(child => child.type === LoggedOut)
    
}

function LoggedOut(props)
{
    return props.children;
}
function LoggedIn(props)
{
    return props.children;
}

