import React from 'react';

import './css/chakraExtension.css';

import './css/index.css';
import './css/Feed.css';

import App from './js/App';
import * as serviceWorker from './js/serviceWorker';

import LanguageHelper from "./js/util/LanguageHelper";

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import store from './js/store';

import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react'
import { globalTheme } from './themes/GlobalTheme';
import { render } from 'react-dom';
import KalakaAPI from './js/util/KalakaAPI';

const { ToastContainer, toast } = createStandaloneToast()
  
console.warn("[Kalaka] Started");

LanguageHelper.initLanguage().then( () => 
    render(<React.StrictMode>
        <ChakraProvider theme={globalTheme}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                    <ToastContainer />
                </BrowserRouter>
            </Provider>
        </ChakraProvider>
    </React.StrictMode>, document.getElementById('root'))
)
/*
const root = createRoot(document.getElementById('root'));

LanguageHelper.initLanguage().then( () => 

    root.render(<React.StrictMode>
        <ChakraProvider theme={globalTheme}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </ChakraProvider>
    </React.StrictMode>)
    
)
root.render("")
*/




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
