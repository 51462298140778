
import { Box, Flex } from "@chakra-ui/react";
import { MdCalendarToday } from "react-icons/md";
import { renderDateString, stringToDate } from "../util/DateHelper";

export const RelativeDate = (props) => {
    if (!props.date)
        return "";

    let date = stringToDate(props.date);

    let d = new Date();
    let late = false;
    if (d > date) {
        late = true;
    }
    return <Box p=".5rem 1rem .5rem calc(.25rem + 6px)" 
        width={"fit-content"} 
        bgColor={late ? "none" : "rgb(186, 238, 175)"} 
        borderLeft={"6px solid " + (late ? "transparent" : "rgb(84, 201, 80)")} >
        <Flex>
            <Box mr={".5rem"} fontSize={"1.5em"}>
                {props.icon || <MdCalendarToday />}
            </Box>
            {props.children}<br></br>
            {renderDateString(props.date, !props.dateOnly)}
        </Flex>
    </Box>;
};
