import { Box, Icon, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import React from "react";
import { MdMore, MdMoreVert, MdOutlineSettings } from "react-icons/md";

export default function OptionsMenu(props)
{
    const [open, setOpen] = React.useState(false);
    
    let children = typeof props.children === "array" ? props.children : [props.children];

    return (
        <Box float="right" m={props.m || props.margin}>
            <Popover
                placement="bottom-end"
                variant={"responsive"}
                bgColor="primary"
            >
                <PopoverTrigger>
                    <IconButton
                        bgColor="transparent"
                        icon={<Icon as={MdMoreVert} w={"1.5rem"} h={"1.5rem"} />}
                        onClick={() => setOpen(!open)}
                    />
                </PopoverTrigger>
                <PopoverContent
                    borderRadius={".3rem"}
                    bgColor="primary">
                    <PopoverArrow bgColor="primary"/>
                    <PopoverBody 
                        p={0}
                        shadow="md"
                        borderRadius={".3rem"}
                    >
                        {children.map((child, i) => <p key={i}>{child}</p>)}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            
        </Box>
    );
}