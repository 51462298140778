import { MdCategory } from "react-icons/md";
import { CategoryTag, ProductCategoryTag } from "../component/CategoryTag";
import PureLink from "../component/PureLink";
import { Card } from "./Card";
import { PostBar, PostDescription, PostTitle, PriceTag } from "./CardComponents";

export function ProductCard(props)
{
    let data = props;
    return <PureLink to={"/product?id=" + props.id}>
        <Card>
            <PostBar>
                <PostTitle>
                    {data.name}
                    <PriceTag price={data.price} unit={data.unit} unitnumber={data.unitnumber}></PriceTag>
                    <ProductCategoryTag c={data.category} />
                </PostTitle>
                <PostDescription>
                    {data.description}
                </PostDescription>
            </PostBar>
        </Card>
    </PureLink>
}