import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, IconButton } from "@chakra-ui/react";
import { MdOutlineDelete, MdOutlineRemoveShoppingCart } from "react-icons/md";

import Checkmark from "../component/CheckMark.js";
import LanguageHelper from "../util/LanguageHelper.js";
import KalakaAPI, { messagePopup, useAPI, usemessagePopup } from "../util/KalakaAPI.js";


import { stringToDate } from "../util/DateHelper";   
import { ToolButton, ToolGroup } from "../component/Tool.js";
import { GetURLParam, ToURLParams } from "../util/URLParams.js";
import { deleteFeed, LoadingFeed } from "../Feed.js";
import ErrorPanel from "../component/ErrorPanel.js";
import { TypeLink } from "../component/TypeLink.js";
import { UserLink } from "../component/UserLink.js";
import { Card } from "../card/Card.js";
import { ContextBar, PostDescription, PostTitle } from "../card/CardComponents.js";
import { GroupLink } from "../component/GroupLink.js";
import { RelativeDeadline } from "../component/RelativeDeadline.js";
import { RelativeDate } from "../component/RelativeDate.js";
import { If } from "../component/If.js";

function Order(props)
{
    const id = props.id || GetURLParam("id")
    const history = useHistory()
    const [error, loading, data, update, deleteOrder] = useAPI({order_id: id}, KalakaAPI.getOrder, KalakaAPI.deleteOrder)

    const onDelete = () => 
    {
        if(window.confirm(LanguageHelper.get("dialogue_confirm")))
            return deleteOrder()
                .then(messagePopup)
                .then((res) => 
                {
                    deleteFeed("sales")
                    res.ok && history.goBack()
                });
    }
    
    const onDone = (data) =>
    {
        return KalakaAPI.doneOrder({...data, order_id: id})
            .then((res) =>
            {
                update()
                messagePopup(res)
                return Promise.resolve(res)
            })
    }
    const render = () => 
    {
        if(loading)
            return <LoadingFeed />
        if(error)
            return <ErrorPanel>{error}</ErrorPanel>
        
        let type = null;
        let edit =  null, del = null, done = null;
        let user = null;
        
        if(data.dealer_id === KalakaAPI.getUserId())
        {
            type = <TypeLink to="/sales">{LanguageHelper.get("Order")}</TypeLink>
            user = <UserLink name={data.user_name} id={data.user_id}/>
            del = <ToolGroup>
                <ToolButton 
                    disabled={data.done == "1"}  
                    onClick={onDelete} 
                    icon={MdOutlineDelete}>
                        {LanguageHelper.get("Delete")}
                </ToolButton>
            </ToolGroup>
            done = <Checkmark 
                    checked={data.done == "1"}
                    onChange={onDone} 
                    label={LanguageHelper.get("Completed")}
                />
        }
        if(data.user_id === KalakaAPI.getUserId())
        {
            type = <TypeLink to="/orders">{LanguageHelper.get("Order")}</TypeLink>;
            user = <UserLink name={data.dealer_name} id={data.dealer_id}/>;
            
            edit = <IconButton >{LanguageHelper.get("Edit")}</IconButton>;
            del = <ToolGroup>
                <ToolButton 
                    disabled={data.done === false || stringToDate(data.order_deadline) < new Date()} 
                    onClick={onDelete} 
                    icon={MdOutlineDelete}>
                        {LanguageHelper.get("Delete")}
                </ToolButton>
            </ToolGroup>
        }
        
        if(data.producer_id === KalakaAPI.getUserId())
        {
            type = <TypeLink to="/sales">{LanguageHelper.get("Order")}</TypeLink>
            user = <UserLink name={data.user_name} id={data.user_id}/>
        }
        
        return <>
            {del}
            <Card>
                <ContextBar>
                    {type}
                    {user}
                    <GroupLink name={data.group_name} id={data.group_id}/>
                </ContextBar>
                <PostTitle>
                    <Link to={"/offer" + ToURLParams({id: data.offer_id})} >{data.product_name}</Link>
                </PostTitle>
                <PostDescription>
                    {data.amount} x {data.unitnumber} {LanguageHelper.get(data.unit)}
                    {" - "} {data.amount * data.price} ft  
                </PostDescription>
                <RelativeDeadline date={data.order_deadline}>{LanguageHelper.get("Order_deadline")}</RelativeDeadline> 
                <RelativeDate delivery_date date={data.delivery_date}>{LanguageHelper.get("Delivery_date")}</RelativeDate>   
               
                {done}
            </Card>
        </>
    }
    return render()
}

export default Order;