import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import "../../css/Calendar.css";
import { setDate, setEvents, setLoading } from "../CalendarSlice";


import { Card } from "../card/Card";
import { ContextBar, PostDescription, PostTitle } from "../card/CardComponents";
import { GroupLink } from "../component/GroupLink";
import { If } from "../component/If";
import Overlay from "../component/Overlay";
import PureLink from "../component/PureLink";
import { TypeLink } from "../component/TypeLink";
import { UserLink } from "../component/UserLink";
import { LoadingFeed } from "../Feed";
import { dateToDatabaseString, stringToDate } from "../util/DateHelper";
import KalakaAPI from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import { ToURLParams } from "../util/URLParams";
import { Box, BreadcrumbLink, Flex, IconButton, Spacer, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { MdArrowLeft, MdArrowRight, MdClear, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { ToolButton } from "../component/Tool";

const monthNames = ["Jan","Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

/**
 * event: {
 *      date: [date]
 *      type: 
 *          event | event_deadline | event_applied
 *          order_deadline | purchase_date | delivery_date
 *      name: [string]
 *      id: [number]
 * }
 */


function Calendar(props)
{
    const dispatch = useDispatch();
    const select = (key) =>
    {
        return state => state.calendar[key]
    }
    const loading = useSelector(select("loading"))
    const data = useSelector(select("events"))

    let date = stringToDate(useSelector(select("date")))
    
    let [open, setOpen] = useState(-10);

    const getData = () =>
    {
        KalakaAPI.getCalendar()
        .then((res) => {
            dispatch(setLoading(false))
            dispatch(setEvents(res.data))
        })
    }
    useEffect(() => {
        getData()
    }, [])
    
    const prevMonth = () =>
    {
        let m = date.getMonth() - 1;
        let y = date.getFullYear();
        if(m === -1) 
        {
            y--;
            m = 11;
        }
        date.setYear(y)
        date.setMonth(m)
        dispatch(setDate(dateToDatabaseString(date)))
    }
    const nextMonth = () =>
    {
        let m = date.getMonth() + 1;
        let y = date.getFullYear();
        if(m === 12) 
        {
            y++;
            m = 0;
        }
        date.setYear(y)
        date.setMonth(m)
        dispatch(setDate(dateToDatabaseString(date)))
    }
    const daysInMonth = () =>
    {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }
    let max = daysInMonth(date.getFullYear(), date.getMonth());
    let today = new Date();

    let events = data;
    let days = [];
    
    date.setDate(1);
    
    // what day does the month start on?
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let firstDayOffset = firstDay.getDay();
    if(firstDayOffset === 0) 
        firstDayOffset = 7;
    for(let i = 1; i < firstDayOffset; i++)
        days.push(-i);
    for(let i = 1; i <= max; i++)
        days.push(i);


    if(loading)
        return <LoadingFeed />
    return(
    <Card>
        <Flex>
            <ToolButton icon={MdKeyboardArrowLeft} onClick={prevMonth}/>
            <Spacer />
            <Box lineHeight={"2.8rem"} fontWeight={"700"} ml={"1rem"} mr={"1rem"}>
                <big>{LanguageHelper.get("month_" + (date.getMonth() + 1)) + " " + date.getFullYear()}</big>
            </Box>
            <Spacer />
            <ToolButton icon={MdKeyboardArrowRight} onClick={nextMonth}/>
        </Flex>
        
        {/* table-layout: fixed; */}
        <Table>
            <Thead>
                <Tr>
                    {Array(7).fill(0).map((elem, id) => 
                        <Th key={id} textAlign={"center"}>
                            {LanguageHelper.get("day_" + (id + 1))}
                        </Th>
                    )}
                </Tr>
            </Thead>
            <If key={"notloading"} s={!loading}>
                <Tbody>
                    {Array(Math.ceil(days.length / 7)).fill(0).map((__, week) =>
                        <Tr key={week} h="6rem">
                            
                        {days.filter((day, count) => count >= (week) * 7 && count < (week + 1) * 7).map((day) => 
                        {
                            let active = day > 0 && day <= max;
                            let isToday = today.getDate() === day && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear(); 
                            
                            let filteredevents = events.filter(event => {
                                let eventdate = stringToDate(event.date)
                                return eventdate.getDate() === day && eventdate.getMonth() === date.getMonth() && eventdate.getFullYear() === date.getFullYear()
                            });

                            let eventgroups = {}
                            filteredevents.forEach(event => {
                                let own = event.owner_id == KalakaAPI.getUserId() ? "own" : ""
                                if(eventgroups[event.type + own])
                                {
                                    eventgroups[event.type + own].count++
                                }
                                else 
                                {
                                    eventgroups[event.type + own] = {
                                        type: event.type,
                                        own: own,
                                        count: 1
                                    }
                                }
                            })

                            filteredevents = filteredevents.map(event =>
                            {
                                return {...event, own: event.owner_id == KalakaAPI.getUserId()}
                            })

                            let eventItems = Object.entries(eventgroups).map(([type, event]) => <CalendarIcon key={type} event={event}/>);

                            return (<>
                                <Td key={day}
                                    verticalAlign={"top"}
                                    p={".5rem .3rem .5rem .5rem"}
                                    borderWidth={"3px"}
                                    borderColor={"primary"}
                                    borderRadius={".5rem"}
                                    bgColor={isToday ? "green.100" : active ? "gray.200" : "primary"}
                                    cursor={active && filteredevents.length > 0 ? "pointer" : "default"}
                                    onClick={() => active && filteredevents.length > 0 ? setOpen(day) : null}
                                >
                                    <If s={day > 0}>
                                        <p><b>{day}</b></p>
                                    </If>
                                    <Box>
                                        {eventItems}
                                    </Box>
                                </Td>
                                <CalendarPopup 
                                    key={day+"i"} 
                                    events={filteredevents} 
                                    open={open === day} 
                                    setOpen={setOpen} 
                                    year={date.getFullYear()}
                                    month={date.getMonth()}
                                    day={day}
                                    
                                    />
                            </>)
                        })}
                        </Tr>
                    )}
                </Tbody>
            </If>
        </Table>
        
            
    </Card>
    );
}

const CalendarIcon = React.memo((props) =>
{
    let event = props.event
    if(event.count === 1)
        event.count = undefined
    //const CalendarItem = types[event.type]
    
    let bgColor, borderRadius = "3px";
    switch(event.type)
    {
        case "event":
            bgColor = "blue.500"
            break;
        case "event_deadline":
            bgColor = "red.500"
            break;
        case "event_applied":
            bgColor = "green.500"
            break;
        case "order_deadline":
            bgColor = "red.500"
            borderRadius = "50%"
            break;
        case "purchase_date":
            bgColor = "yellow.500"
            borderRadius = "50%"
            break;
        case "delivery_date":
            bgColor = "green.500";
            borderRadius = "50%"
            break;
        default:
            bgColor = "gray.500";
            break;
    }
    
    
    return <Box key={event.type}
        position={"relative"}
        display={"inline-block"}
        w={props.card ? "1rem" : "1.1rem"}
        h={props.card ? "1rem" : "1.1rem"}
        mr={"0.2rem"}
        mt={"-.1rem"}
        p={0}
        color={event.own ? "white" : "black"}
        bgColor={event.own ? bgColor : "transparent"}
        borderColor={bgColor}
        borderWidth={"4px"}
        borderRadius={borderRadius}
        >
        <Box
            textAlign={"center"}
            fontSize={"0.8rem"}
            fontWeight={"700"}
            color={event.own ? "white" : "black"}
            position={"absolute"}
            top={"-.25rem"}
            left={"0"}
            right={"0"}
             
            >
            {/* event.count */}
        </Box>
    </Box>
})

const CalendarPopup = (props) => 
{
    let events = props.events
    return  <Overlay invisible open={props.open} hideCloseButton >
        <Card>
            <PostTitle>
                {new Date(events[0]?.date).toLocaleDateString()}
                <IconButton float={"right"} mt="-.2rem" mr="-.2rem" bgColor={"var(--secondary)"} icon={<MdClear />} onClick={() => props.setOpen(false)}/>
            </PostTitle>
        </Card>
            {
                events.map(event => 
                    <Card key={event.type + event.id}>
                        <ContextBar>
                            <TypeLink to={(event.type.includes("event") ? "/events" : "/market")}>
                                {event.type.includes("event") ? LanguageHelper.get("Event") : LanguageHelper.get("Offer") }
                            </TypeLink> 
                            <UserLink name={event.owner_name} id={event.owner_id}/>
                            <GroupLink name={event.group_name} id={event.group_id}/>
                        </ContextBar>
                        <PureLink to={(event.type.includes("event") ? "/event" : "/offer") + ToURLParams({id: event.id})}>
                            <PostTitle>
                                {event.name}
                            </PostTitle>
                            {event.icon}
                            <PostDescription>
                                <Flex alignItems={"center"}>
                                    <CalendarIcon card event={event}/>
                                    <Box ml={"0.5rem"}>
                                        {LanguageHelper.get("calendar_" + event.type)}
                                    </Box>
                                </Flex>
                            </PostDescription>
                        </PureLink>
                    </Card>
                )
            }
    </Overlay>
}


export default Calendar;