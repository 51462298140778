import React from "react";
import { Flex, Spacer, Text, useToast } from "@chakra-ui/react";
import { MdOutlineDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Card } from "../card/Card";
import { ContextBar, FeedTitle, PostDescription, PostTitle } from "../card/CardComponents";
import CheckMark from "../component/CheckMark";
import ErrorPanel from "../component/ErrorPanel";
import Form, { NumberInput } from "../component/Form";
import { GroupLink } from "../component/GroupLink";
import { If } from "../component/If";
import PureLink from "../component/PureLink";
import { RelativeDate } from "../component/RelativeDate";
import { RelativeDeadline } from "../component/RelativeDeadline";
import { ToolButton, ToolGroup } from "../component/Tool.js";
import { TypeLink } from "../component/TypeLink";
import { UserLink } from "../component/UserLink";
import { deleteFeed, LoadingFeed } from "../Feed";
import KalakaAPI, { messagePopup, useAPI } from "../util/KalakaAPI";
import LanguageHelper from "../util/LanguageHelper";
import { GetURLParam, ToURLParams } from "../util/URLParams";
import Product from "./Product";

export default function Offer(props)
{
    const id = props.id || GetURLParam("id");
    
    const dispatch = useDispatch()
    const history = useHistory()
    
    const toast = useToast()
    
    const [error, loading, data, update, deleteOffer] = useAPI({offer_id: id}, KalakaAPI.getOffer, KalakaAPI.deleteOffer)

    const onDelete = () =>
    {
        if(window.confirm(LanguageHelper.get("confirm_delete")))
        {
            deleteOffer().then(() =>
            {
                deleteFeed("market")
                deleteFeed("myoffers")
                deleteFeed("main")
                toast({
                    title: LanguageHelper.get("Deleted"),
                    status: "success"
                })
                
                history.goBack()
                
            })
        }
    }

    if(error)
        return <ErrorPanel>{error}</ErrorPanel>
    if(loading)
        return <LoadingFeed />
        
    return (
        <>
            <If s={data.dealer_id === KalakaAPI.getUserId() || data.producer_id === KalakaAPI.getUserId()}>
                <ToolGroup>
                    <ToolButton onClick={onDelete} icon={MdOutlineDelete}>{LanguageHelper.get("Delete_offer")}</ToolButton>
                </ToolGroup>
            </If>
            
            <Product id={data.product_id} offer contextBar={<ContextBar>
                <TypeLink to="/market">{LanguageHelper.get("Offer") + " "}</TypeLink>
                <UserLink id={data.dealer_id} name={data.dealer_name}></UserLink>
                <GroupLink id={data.group_id} name={data.group_name}></GroupLink>
            </ContextBar>} />
            
            <Card>
                
                <div className="half">
                    <RelativeDeadline date={data.order_deadline}>{LanguageHelper.get("Order_deadline")}</RelativeDeadline>
                    <RelativeDate dateOnly date={data.delivery_date}>{LanguageHelper.get("Delivery_date")}</RelativeDate>
                </div>
                {/* renders only if order_deadline ha not expired, order_deadline is in ISO format*/}
                <If s={data.order_deadline > new Date().toISOString()}>
                    <div className="half">
                        <Form key="612263784"
                            text={LanguageHelper.get("Order_placement")} 
                            onSubmit={(d) => KalakaAPI.order({...d, offer_id: data.offer_id})}
                            onSuccess={update}
                            messageOnSuccess={LanguageHelper.get("Order_placed")}
                            > 
                            <NumberInput
                                name="amount"
                                label={LanguageHelper.get("Amount") + " (" + data.unitnumber + " " + LanguageHelper.get(data.unit) + ")"}
                                
                                >
                            </NumberInput>
                        </Form>      
                    </div>
                </If>
            </Card>
            
            <If s={data.dealer_id == KalakaAPI.getUserId() || data.producer_id == KalakaAPI.getUserId()}>
                <FeedTitle>
                    {LanguageHelper.get("Sales")}
                </FeedTitle>
                <Card>
                    <If s={data.orders && data.orders.length > 0}>
                        {data.orders.map(order =>
                            <Flex key={order.id} alignItems={"center"} w={"100%"} transition={".2s"} cursor={"pointer"} _hover={{bgColor: "var(--secondarycolor)"}}> 
                                <PureLink to={"/sale" + ToURLParams({id: order.id})}>
                                    <Flex alignItems={"center"} w={"100%"} ml={"1rem"}>
                                        <UserLink inactive id={order.user_id} name={order.user_name} />
                                        <Spacer />
                                        <Text>
                                            {order.amount} {" x "} 
                                            {data.unitnumber} {" "}
                                            {LanguageHelper.get(data.unit)}
                                        </Text>
                                    </Flex>
                                </PureLink>
                                <Spacer />
                                <CheckMark
                                    right 
                                    checked={order.done === "1"}
                                    onChange={(d) => KalakaAPI.doneOrder({...d, order_id: order.id})
                                        .then(messagePopup)}
                                />
                            </Flex>
                        )}
                    </If>
                    <If s={data.orders?.length === 0}>
                        <PostDescription> {LanguageHelper.get("Empty_list")}</PostDescription>
                    </If>
                </Card>
            </If>
            
        </>
    )
}