import React from 'react';

import PureLink from './PureLink';
import { Button, Icon, } from '@chakra-ui/react';

export function NavButton(props)
{
    const button = <Button 
        borderRadius={".2rem"} 
        justifyContent={"flex-start"}
        w={"calc(100% - 1rem)"}
        m={".2rem .5rem .2rem .5rem"} 
        _hover={{bg: "primary", shadow: "md"}} 
        bgColor={"secondary"}
        leftIcon={<Icon as={props.icon} w={"2rem"} h={"2rem"} />}>
            {props.children}
    </Button>
    if(props.to)
        return <div onClick={props.onClick || (() => {})}>
            <PureLink to={props.to || "404"} style={{ textDecoration: 'none', color: 'black'}}>
                {button}
            </PureLink>
        </div>
    else
        return <div onClick={props.onClick || (() => {})}> 
            {button}
        </div> 
}